html, body {
    height: 100%;
}

$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

body {
    display: flex;
    flex-direction: column;
    padding-right: 0 !important;
    height: auto;
    margin: 0;
    @media #{$mq-retina} {
      font-size: 12px;
    }
}

img {
    width: auto;
}

a, a:link, a:visited {
    color: $color-primary-blue;
    text-decoration: none;
    font-family: 'NunitoSans-Light';
    &.white-link {
      color: white;
      text-decoration: underline;
    }
    &.dark-bg-link {
      color: $color-blue-dark-bg;
    }
    &.new-tab-link {
      position: relative;
      &:after {
        position: absolute;
        opacity: 0;
        transition: opacity .25s ease;
        content: "\f35d";
        display: inline-block;
        font-family: fontawesome;
        margin-left: 0.25em;
        vertical-align: super;
        margin-left: 0.25em;
        font-size: .625em;
        background-color: white;
      }
      &:hover::after {
        opacity: 1;
      }
    }
}
a:hover {
    color: $color-secondary-blue;
    text-decoration: none;
}

// We need to specify H1..H6 for pages and separetely for app-layout
h1, h2, h3, h4, h5, h6 {
  font-family: 'NunitoSans-Bold';
  color: #69626f;
  font-family: "NunitoSans-Light";
  color: white;
}

// this needs to be specified for app-layout
label {
    font-family: 'NunitoSans';
    margin-bottom: 2px;
    font-weight: 700;
}

.float-to-left {
  float: left;
}
.float-to-right {
  float: right;
}

.iframe-container {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.clear {
  clear: both;
}

.icon-before-text {
    margin-right: 0.5em;
}

.wrapper {
    min-height: 0;
}

// ###########################################################
// this was used on About page - tools section as tag for each tool
// ToDo: Keep it for now and maybe reuse

.category-label {
  display: inline-block;
  font-size: .9em;
  padding: .15em .5em;
  text-transform: lowercase;
  font-family: "NunitoSans";
  position: relative;
  
  &.category-label-blue {
    background: #84b6eb;
  }
  &.category-label-yellow {
    background: #fcdb94;
  }
}
// ###########################################################


// We might need back to top button (however the pages are short
#backToTop {
  display: inline-block;
  background-color: #6140b4;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: all .3s, 
  opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  padding: 0;
  
  &:after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 2em;
    line-height: 50px;
    color: #fff;
    margin-top: -.2em;
    position: relative;
    display: block;
  }

  @media screen and (max-width: 440px) {
    right: 10px;
    width: 35px;
    height: 35px;
    &:after {
      line-height: 35px;
      font-size: 1.75em;
    }
  }


  &:hover {
    cursor: pointer;
    background-color: #8a61ef;
  }
  &:active {
    background-color: #51388e;
  }
  &.show {
    opacity: 1;
    visibility: visible;
  }
  &.bottom-of-document {
    @media screen and (max-width: 800px) {
      bottom: 100px;
    }
  }

  .gdpr-popup-displayed & {
    margin-bottom: 100px;
    @media screen and (max-width: 800px) {
      margin-bottom: 150px;
    }
    @media screen and (max-width: 550px) {
      margin-bottom: 200px;
    }
    @media screen and (max-width: 520px) {
      margin-bottom: 250px;
    }
  }
}