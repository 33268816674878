

// @import "../../common/goldnineheader/goldnineheader.scss";
// @import "../../common/goldninefooter/goldninefooter.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";


@import "./partials/variables";
@import "./app.fonts.scss";
@import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
@import '~ngx-toastr/toastr';
@import "./partials/defaults";
@import "./partials/layout";
@import "./partials/_app_layout.scss";
@import "./partials/content_layout";
@import "./partials/buttons";
@import "./partials/inputs";
@import "./partials/modals";
@import "./partials/datepicker";


@keyframes dropdownmenu-show {
  0% {
    // display: none;
    opacity: 0;
    visibility: hidden;
    transform: translate(0,-10px);
    // margin-top: 0px;
  }
  1% {
    // display: block;
    opacity: 0;
    visibility: visible;
    transform: translate(0,-10px);
    // margin-top: 0px;
  }
  100% {
    // display: block;
    opacity: 1;
    visibility: visible;
    transform: translate(0,0px);
    // margin-top: 10px;
  }
}

@keyframes dropdownmenu-hide {
  0% {
    // display: block !important;
    opacity: 1;
    visibility: visible;
  }
  99% {
    // display: block !important;
    visibility: visible;
    opacity: 0;
  }
  100% {
    // display: none;
    visibility: hidden;
    opacity: 0;
  }
}


.dropdown-menu:not(typeahead-container) {
  padding: 10px;
  // display: block;
  // visibility: hidden;
  animation: dropdownmenu-hide .2s ease-in;
  animation-fill-mode: forwards;
  // margin-top: 10px;
  &.show {
    animation: dropdownmenu-show .25s ease-out;
    // margin-top: 10px;
  }
}



section#aboutPaterns {
  .video-tutorial {
    width: 100%;
    max-width: 800px;
    margin: 2em auto;
    box-shadow: 0 0 25px 0 rgba(147,112,219,0.2);
  }
  article > ul {
    padding: 0;
    & > li {
      display: flex;
      flex-direction: column;
      ul, ol {
        flex-grow: 1;
        padding-left: 2em;
        li {
          font-size: 1em;
          margin-bottom: 0.75em;
        }
      }
      .iframe-container {
        margin: 2em auto;
        iframe {
          width: 90%;
          height: 90%;
          left: 5%;
          top: 5%;
        }
      }
    }
  }
}


.code-conversion-arrow {
  font-size: 5em;
    color: mediumpurple;
    margin: .1em 0 0.05em;
    padding: 0;
    text-align: center;
    line-height: 1;
}

/*Compile Button CSS*/

.btn{
  margin: 4px;
  box-shadow: 1px 1px 5px #888888;
}

.btn-sky {
color: #fff;
background-color: #0bacd3;
border-bottom:2px solid #098aa9;
}

.btn-sky:hover,.btn-sky.active:focus, .btn-sky:focus{
color: #fff;
background-color: #29b6d8;
border-bottom:2px solid #2192ad;
outline: none;
}

.btn-sky:active, .btn-sky.active {
color: #fff;
background-color: #0a97b9;
border-top:2px solid #087994;
outline-offset: none;
margin-top: 2px;
}

.btn-sky.active:focus{
color: #fff;
background-color: #df6a78;
border-bottom:2px solid #b25560;
outline: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none;
  outline-offset: 0px;
}

/*Compile Button CSS End*/



.ace_gutter {
  min-width: 50px;
}

.ace_scroller {
margin-left: 5px;
}

input#compile {
  margin-top: 5px;
  float: right;
}

/*Language Select CSS */
button.btn.dropdown-toggle.btn-default {
  width: 42%;
  margin: 4px 2px 6px 4px;
}

.ace-monokai .ace_print-margin {
  width: 0;
  background: none;
}





#aboutFreeTools {
  ul {
    li {
      margin-bottom: 2em;
    }
  }
}


.nav-and-logo {
    box-shadow: 0 0 25px -10px rgba(0,0,0,0.1);
    z-index: 1;
    position: relative;
}


.banner-wrap {
    background-image: url("/assets/images/banner-sm.jpg");
    position: relative;
    background-repeat: no-repeat;
    max-width: none;
    height: 350px;
    background-position-x: right;
    background-position-y: 45%;
    background-color: #f9f9f9;

    background-size: calc(50% + 300px) auto;

    .web-app & {
      height: 100px;
    }

    @media screen and (max-width: 1050px) {
      height: 300px;
    }

    @media screen and (max-width: 700px) {
      height: 250px;
      background-size: cover;
    }

    @media screen and (max-width: 550px) {
      background-image: url("/assets/images/banner-sm.jpg");
    }

    @media screen and (min-width: 551px) and (max-width: 700px) {
      background-image: url("/assets/images/banner-md.jpg");
    }

    @media screen and (min-width: 701px) and (max-width: 1200px) {
      background-image: url("/assets/images/banner-lg.jpg");
    }

    @media screen and (min-width: 1201px) {
      background-image: url("/assets/images/banner-xlg.jpg");
    }
}





// General styles


// color schemes

// 1. from https://saascss.com/interviewed





// Styles to overwrite style.css - to be removed if not needed








textarea {
    resize: vertical;
    max-height: 250px;
    width: 100%;
    height: 135px;
    min-height: 135px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.form-group {
    margin-bottom: 12px;

    &.no-bottom-margin {
      margin-bottom: 0;
    }

    &.min-bottom-margin {
      margin-bottom: 1em;
    }


    &.for-buttons {
        padding-top: 5px;
    }
    &.space-for-errors {
      min-height: 86px;
    }
    &.form-button-container {
      margin-bottom: 2em;
    }
}
input::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-family: 'NunitoSans-LightItalic';
}
.form-control {
    color: black;
    font-family: 'NunitoSans';
    border-radius: 0;
    background-color: white;
    transition: width .35s ease-in-out;
    &::placeholder {
        color: rgba(0, 0, 0, 0.4);
        font-family: 'NunitoSans-LightItalic';
    }
    &.is-valid, &.is-invalid {
      background-image: none;
      padding-right: 0;
      @media screen and (max-width: 600px) {
        width: calc(100% - 1.25em);
      }
    }
    &.is-valid:after {
      display: block;
      content: "";
      border-color: #28a745;
      padding-right: calc(1.5em + .75rem);

    }
}

@-webkit-keyframes autofill {
  to {
      background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-color: red;
      background-position: right calc(.375em + .1875rem) center;
      background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  }
}
input, textarea, select {
  &.is-valid {
    &:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus {
      border-color: #28a745;
      background: none;
      // padding-right: calc(1.5em + .75rem);
      // background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      // background-repeat: no-repeat;
      // background-color: red;
      // background-position: right calc(.375em + .1875rem) center;
      // background-size: calc(.75em + .375rem) calc(.75em + .375rem);
      // -webkit-animation-name: autofill forwards;
    //   animation-name: autofill;
    // animation-fill-mode: both;
    // animation: autofill forwards;
      // -webkit-animation-fill-mode: both;
    }
  }
}

@keyframes grow {
  from {
    transform: scale(.5);
    // opacity: 0;
  }
  to {
    transform: scale(1);
    // opacity: 1;
  }
}

@keyframes bounceIn1 {
  0% {
     opacity: 0;
     transform: scale(.3);
  }
  30% {
     opacity: 1;
     transform: scale(1.25);
  }
  55% {
     transform: scale(.75);
  }
  75% {
    transform: scale(1.1);
  }
  90% {
    transform: scale(0.9);
  }
  100% {
     transform: scale(1);
  }
}

@keyframes bounceIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}

@keyframes bounceInDelayed {
  0% {
    display: none;
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50% {
    display: block;
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    display: block;
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    display: block;
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}


.input-container {
  position: relative;
  transition: all .35s ease;

  // have below setting for special case (class)
  // padding-right: calc(2.25em + 0.75rem);

  &.is-valid, &.is-invalid {
    padding-right: 1.5em;
  }
  &.is-valid:after {
    display: inline-block;
    content: "\f00c";
    color: #28a745;
    font-family: fontawesome;
    font-size: 1.5em;
    position: absolute;
    right: -1.25em;
    right: -0.375em;
    top: 0.2em;
    opacity: 0;
    animation-name: bounceIn;
    animation-duration: 450ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    @media screen and (max-width: 600px) {
      right: -0.5em;
    }
  }
  &.is-invalid:after {
    display: inline-block;
    content: "\f06a";
    color: #d30707;
    font-family: fontawesome;
    font-size: 1.5em;
    position: absolute;
    right: -1.25em;
    right: -0.375em;
    top: 0.2em;
    opacity: 0;
    animation-name: bounceIn;
    animation-duration: 450ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    @media screen and (max-width: 600px) {
      right: -0.5em;
    }
  }
  &.small {
    &.is-invalid:after, &.is-valid:after {
      top: 0;
    }
  }
}

.character-counter {
  text-align: right;
  font-size: 0.8em;
  // padding-right: calc(2.25em + 0.75rem);
  padding-right: 0.5rem;
  color: #a8a6ae;
  margin-bottom: -1em;
}

// password field show hide button
.show-password {
  position: absolute;
  right: 2.25em;
  top: .45em;
  opacity: 0.75;
  cursor: pointer;
  transition: all .25s ease-in-out;
  &:hover {
    opacity: 1;
  }
  fa-icon {
    transform: rotate(0deg) !important;
    color: #fb8635;
    color: #025aff;
    font-size: 1.25rem;
    position: absolute;
    &:last-child {
      transform: translate(-1px, 0px) rotate(0deg) !important;
    }
  }
}



.form-control-lg {
    font-size: 15px;
    height: 45px;
    padding: 7px 15px;
    border-radius: 0px;
}
textarea.form-control-lg {
  height: auto;
}

option:disabled {
  color: #a8a8a8;
  font-family: 'NunitoSans-LightItalic';
}

.signup-regi:after {
    content: "";
    clear: both;
    display: block;
}


input[type=file]:focus, input[type=checkbox]:focus, input[type=radio]:focus {
    outline: none;
    outline-offset: 0;
}

@keyframes click-wave {
    0% {
      height: 20px;
      width: 20px;
      opacity: 0.35;
      position: relative;
    }
    100% {
      height: 60px;
      width: 60px;
      margin-left: -20px;
      margin-top: -20px;
      opacity: 0;
    }
  }

.option-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 4px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 20px;
    transition: all 0.3s ease-out 0s;
    background: #cbd1d8;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    position: relative;
    z-index: 1000;
    box-shadow: 0 0 2px -2px rgba(0, 0, 0, 0.4);
    background-color: #fff;
    border-radius: 0;
    font-family: 'sansation-light';
    border: 2px solid #3d73d7;
  }
  .option-input:hover, .option-input:focus {
    // border-color: $purple-hover-light;
    // color: white;
    // text-decoration: none;
    // background-color: rgba(97, 64, 180, 0.05);

    border-color: #77a7ff;
    color: #77a7ff;
    text-decoration: none;
    background-color: rgba(119, 167, 255, 0.05);
    box-shadow: 0 0 3px 2px rgb(119 167 255 / 50%);
  }
  .option-input:focus {
    // box-shadow: 0 0 3px 2px rgba(96,56,195,0.53);
    box-shadow: 0 0 3px 2px rgb(119 167 255 / 50%);
  }
  .option-input:checked {
    // background: $purple-primary;
    background: #3d73d7;
    &:hover, &:focus {
        background-color: #77a7ff;
    }
    &:focus {
      box-shadow: 0 0 3px 2px rgb(119 167 255 / 50%);
    }
  }
  .option-input:checked::before {
    position: absolute;
    content: '✔';
    display: inline-block;
    text-align: center;
    height: 20px;
    width: 20px;
    color: white;
    top: -1px;
    left: -2px;
    font: normal normal normal 16px/1 FontAwesome;
    font-weight: 500;
  }
  .option-input:checked::after {
    -webkit-animation: click-wave 0.3s;
    -moz-animation: click-wave 0.3s;
    animation: click-wave 0.3s;
    // background: $purple-primary;
    content: '';
    display: block;
    position: relative;
    z-index: 100;
    background: radial-gradient(0% #77a7ff, 80% #77a7ff, 100% transparent);
  }
  .option-input.radio {
    border-radius: 50%;
  }
  .option-input.radio::after {
    border-radius: 50%;
  }

  .option-input.radio + label {
    cursor: pointer;
  }


.slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.slide.ng-enter,
.slide.ng-leave {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.slide.ng-enter {
  left: 100%;
}
.slide.ng-enter-active {
  left: 0;
}
.slide.ng-leave {
  left: 0;
}
.slide.ng-leave-active {
  left: -100%;
}

.fade {
 // position: absolute;
  top: 0;
  left: 0;
  right: 0;
  //height: 100%;
  opacity: 1;
}
.fade.ng-enter,
.fade.ng-leave {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.fade.ng-enter {
  opacity: 0;
}
.fade.ng-enter-active {
  opacity: 1;
}
.fade.ng-leave {
  opacity: 1;
  //height: 0px;
}
.fade.ng-leave-active {
  opacity: 0;
  //height: 200px;
  position: absolute;
}
.app {
  position: relative;
}





  /*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
.datepicker {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    direction: ltr;
    /*.dow {
          border-top: 1px solid #ddd !important;
      }*/

  }
  .datepicker-inline {
    width: 220px;
  }
  .datepicker.datepicker-rtl {
    direction: rtl;
  }
  .datepicker.datepicker-rtl table tr td span {
    float: right;
  }
  .datepicker-dropdown {
    top: 0;
    left: 0;
  }
  .datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
  }
  .datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-top: 0;
    position: absolute;
  }
  .datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
  }
  .datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
  }
  .datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
  }
  .datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
  }
  .datepicker-dropdown.datepicker-orient-top:before {
    top: -7px;
  }
  .datepicker-dropdown.datepicker-orient-top:after {
    top: -6px;
  }
  .datepicker-dropdown.datepicker-orient-bottom:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
  }
  .datepicker-dropdown.datepicker-orient-bottom:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #ffffff;
  }
  .datepicker > div {
    display: none;
  }
  .datepicker.days div.datepicker-days {
    display: block;
  }
  .datepicker.months div.datepicker-months {
    display: block;
  }
  .datepicker.years div.datepicker-years {
    display: block;
  }
  .datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .datepicker td,
  .datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: none;
  }
  .table-striped .datepicker table tr td,
  .table-striped .datepicker table tr th {
    background-color: transparent;
  }
  .datepicker table tr td.day:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  .datepicker table tr td.old,
  .datepicker table tr td.new {
    color: #999999;
  }
  .datepicker table tr td.disabled,
  .datepicker table tr td.disabled:hover {
    background: none;
    color: #999999;
    cursor: default;
  }
  .datepicker table tr td.today,
  .datepicker table tr td.today:hover,
  .datepicker table tr td.today.disabled,
  .datepicker table tr td.today.disabled:hover {
    background-color: #fde19a;
    background-image: -moz-linear-gradient(to top, #fdd49a, #fdf59a);
    background-image: -ms-linear-gradient(to top, #fdd49a, #fdf59a);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
    background-image: -webkit-linear-gradient(to top, #fdd49a, #fdf59a);
    background-image: -o-linear-gradient(to top, #fdd49a, #fdf59a);
    background-image: linear-gradient(to top, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #000;
  }
  .datepicker table tr td.today:hover,
  .datepicker table tr td.today:hover:hover,
  .datepicker table tr td.today.disabled:hover,
  .datepicker table tr td.today.disabled:hover:hover,
  .datepicker table tr td.today:active,
  .datepicker table tr td.today:hover:active,
  .datepicker table tr td.today.disabled:active,
  .datepicker table tr td.today.disabled:hover:active,
  .datepicker table tr td.today.active,
  .datepicker table tr td.today:hover.active,
  .datepicker table tr td.today.disabled.active,
  .datepicker table tr td.today.disabled:hover.active,
  .datepicker table tr td.today.disabled,
  .datepicker table tr td.today:hover.disabled,
  .datepicker table tr td.today.disabled.disabled,
  .datepicker table tr td.today.disabled:hover.disabled,
  .datepicker table tr td.today[disabled],
  .datepicker table tr td.today:hover[disabled],
  .datepicker table tr td.today.disabled[disabled],
  .datepicker table tr td.today.disabled:hover[disabled] {
    background-color: #fdf59a;
  }
  .datepicker table tr td.today:active,
  .datepicker table tr td.today:hover:active,
  .datepicker table tr td.today.disabled:active,
  .datepicker table tr td.today.disabled:hover:active,
  .datepicker table tr td.today.active,
  .datepicker table tr td.today:hover.active,
  .datepicker table tr td.today.disabled.active,
  .datepicker table tr td.today.disabled:hover.active {
    background-color: #fbf069 \9;
  }
  .datepicker table tr td.today:hover:hover {
    color: #000;
  }
  .datepicker table tr td.today.active:hover {
    color: #fff;
  }
  .datepicker table tr td.range,
  .datepicker table tr td.range:hover,
  .datepicker table tr td.range.disabled,
  .datepicker table tr td.range.disabled:hover {
    background: #eeeeee;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .datepicker table tr td.range.today,
  .datepicker table tr td.range.today:hover,
  .datepicker table tr td.range.today.disabled,
  .datepicker table tr td.range.today.disabled:hover {
    background-color: #f3d17a;
    background-image: -moz-linear-gradient(to top, #f3c17a, #f3e97a);
    background-image: -ms-linear-gradient(to top, #f3c17a, #f3e97a);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
    background-image: -webkit-linear-gradient(to top, #f3c17a, #f3e97a);
    background-image: -o-linear-gradient(to top, #f3c17a, #f3e97a);
    background-image: linear-gradient(to top, #f3c17a, #f3e97a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
    border-color: #f3e97a #f3e97a #edde34;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .datepicker table tr td.range.today:hover,
  .datepicker table tr td.range.today:hover:hover,
  .datepicker table tr td.range.today.disabled:hover,
  .datepicker table tr td.range.today.disabled:hover:hover,
  .datepicker table tr td.range.today:active,
  .datepicker table tr td.range.today:hover:active,
  .datepicker table tr td.range.today.disabled:active,
  .datepicker table tr td.range.today.disabled:hover:active,
  .datepicker table tr td.range.today.active,
  .datepicker table tr td.range.today:hover.active,
  .datepicker table tr td.range.today.disabled.active,
  .datepicker table tr td.range.today.disabled:hover.active,
  .datepicker table tr td.range.today.disabled,
  .datepicker table tr td.range.today:hover.disabled,
  .datepicker table tr td.range.today.disabled.disabled,
  .datepicker table tr td.range.today.disabled:hover.disabled,
  .datepicker table tr td.range.today[disabled],
  .datepicker table tr td.range.today:hover[disabled],
  .datepicker table tr td.range.today.disabled[disabled],
  .datepicker table tr td.range.today.disabled:hover[disabled] {
    background-color: #f3e97a;
  }
  .datepicker table tr td.range.today:active,
  .datepicker table tr td.range.today:hover:active,
  .datepicker table tr td.range.today.disabled:active,
  .datepicker table tr td.range.today.disabled:hover:active,
  .datepicker table tr td.range.today.active,
  .datepicker table tr td.range.today:hover.active,
  .datepicker table tr td.range.today.disabled.active,
  .datepicker table tr td.range.today.disabled:hover.active {
    background-color: #efe24b \9;
  }
  .datepicker table tr td.selected,
  .datepicker table tr td.selected:hover,
  .datepicker table tr td.selected.disabled,
  .datepicker table tr td.selected.disabled:hover {
    background-color: #9e9e9e;
    background-image: -moz-linear-gradient(to top, #b3b3b3, #808080);
    background-image: -ms-linear-gradient(to top, #b3b3b3, #808080);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
    background-image: -webkit-linear-gradient(to top, #b3b3b3, #808080);
    background-image: -o-linear-gradient(to top, #b3b3b3, #808080);
    background-image: linear-gradient(to top, #b3b3b3, #808080);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
    border-color: #808080 #808080 #595959;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .datepicker table tr td.selected:hover,
  .datepicker table tr td.selected:hover:hover,
  .datepicker table tr td.selected.disabled:hover,
  .datepicker table tr td.selected.disabled:hover:hover,
  .datepicker table tr td.selected:active,
  .datepicker table tr td.selected:hover:active,
  .datepicker table tr td.selected.disabled:active,
  .datepicker table tr td.selected.disabled:hover:active,
  .datepicker table tr td.selected.active,
  .datepicker table tr td.selected:hover.active,
  .datepicker table tr td.selected.disabled.active,
  .datepicker table tr td.selected.disabled:hover.active,
  .datepicker table tr td.selected.disabled,
  .datepicker table tr td.selected:hover.disabled,
  .datepicker table tr td.selected.disabled.disabled,
  .datepicker table tr td.selected.disabled:hover.disabled,
  .datepicker table tr td.selected[disabled],
  .datepicker table tr td.selected:hover[disabled],
  .datepicker table tr td.selected.disabled[disabled],
  .datepicker table tr td.selected.disabled:hover[disabled] {
    background-color: #808080;
  }
  .datepicker table tr td.selected:active,
  .datepicker table tr td.selected:hover:active,
  .datepicker table tr td.selected.disabled:active,
  .datepicker table tr td.selected.disabled:hover:active,
  .datepicker table tr td.selected.active,
  .datepicker table tr td.selected:hover.active,
  .datepicker table tr td.selected.disabled.active,
  .datepicker table tr td.selected.disabled:hover.active {
    background-color: #666666 \9;
  }
  .datepicker table tr td.active,
  .datepicker table tr td.active:hover,
  .datepicker table tr td.active.disabled,
  .datepicker table tr td.active.disabled:hover {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to top, #0088cc, #0044cc);
    background-image: -ms-linear-gradient(to top, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to top, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to top, #0088cc, #0044cc);
    background-image: linear-gradient(to top, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .datepicker table tr td.active:hover,
  .datepicker table tr td.active:hover:hover,
  .datepicker table tr td.active.disabled:hover,
  .datepicker table tr td.active.disabled:hover:hover,
  .datepicker table tr td.active:active,
  .datepicker table tr td.active:hover:active,
  .datepicker table tr td.active.disabled:active,
  .datepicker table tr td.active.disabled:hover:active,
  .datepicker table tr td.active.active,
  .datepicker table tr td.active:hover.active,
  .datepicker table tr td.active.disabled.active,
  .datepicker table tr td.active.disabled:hover.active,
  .datepicker table tr td.active.disabled,
  .datepicker table tr td.active:hover.disabled,
  .datepicker table tr td.active.disabled.disabled,
  .datepicker table tr td.active.disabled:hover.disabled,
  .datepicker table tr td.active[disabled],
  .datepicker table tr td.active:hover[disabled],
  .datepicker table tr td.active.disabled[disabled],
  .datepicker table tr td.active.disabled:hover[disabled] {
    background-color: #0044cc;
  }
  .datepicker table tr td.active:active,
  .datepicker table tr td.active:hover:active,
  .datepicker table tr td.active.disabled:active,
  .datepicker table tr td.active.disabled:hover:active,
  .datepicker table tr td.active.active,
  .datepicker table tr td.active:hover.active,
  .datepicker table tr td.active.disabled.active,
  .datepicker table tr td.active.disabled:hover.active {
    background-color: #003399 \9;
  }
  .datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .datepicker table tr td span:hover {
    background: #eeeeee;
  }
  .datepicker table tr td span.disabled,
  .datepicker table tr td span.disabled:hover {
    background: none;
    color: #999999;
    cursor: default;
  }
  .datepicker table tr td span.active,
  .datepicker table tr td span.active:hover,
  .datepicker table tr td span.active.disabled,
  .datepicker table tr td span.active.disabled:hover {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to top, #0088cc, #0044cc);
    background-image: -ms-linear-gradient(to top, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(to top, #0088cc, #0044cc);
    background-image: -o-linear-gradient(to top, #0088cc, #0044cc);
    background-image: linear-gradient(to top, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .datepicker table tr td span.active:hover,
  .datepicker table tr td span.active:hover:hover,
  .datepicker table tr td span.active.disabled:hover,
  .datepicker table tr td span.active.disabled:hover:hover,
  .datepicker table tr td span.active:active,
  .datepicker table tr td span.active:hover:active,
  .datepicker table tr td span.active.disabled:active,
  .datepicker table tr td span.active.disabled:hover:active,
  .datepicker table tr td span.active.active,
  .datepicker table tr td span.active:hover.active,
  .datepicker table tr td span.active.disabled.active,
  .datepicker table tr td span.active.disabled:hover.active,
  .datepicker table tr td span.active.disabled,
  .datepicker table tr td span.active:hover.disabled,
  .datepicker table tr td span.active.disabled.disabled,
  .datepicker table tr td span.active.disabled:hover.disabled,
  .datepicker table tr td span.active[disabled],
  .datepicker table tr td span.active:hover[disabled],
  .datepicker table tr td span.active.disabled[disabled],
  .datepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #0044cc;
  }
  .datepicker table tr td span.active:active,
  .datepicker table tr td span.active:hover:active,
  .datepicker table tr td span.active.disabled:active,
  .datepicker table tr td span.active.disabled:hover:active,
  .datepicker table tr td span.active.active,
  .datepicker table tr td span.active:hover.active,
  .datepicker table tr td span.active.disabled.active,
  .datepicker table tr td span.active.disabled:hover.active {
    background-color: #003399 \9;
  }
  .datepicker table tr td span.old,
  .datepicker table tr td span.new {
    color: #999999;
  }
  .datepicker th.datepicker-switch {
    width: 145px;
  }
  .datepicker thead tr:first-child th,
  .datepicker tfoot tr th {
    cursor: pointer;
  }
  .datepicker thead tr:first-child th:hover,
  .datepicker tfoot tr th:hover {
    background: #eeeeee;
  }
  .datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
  .datepicker thead tr:first-child th.cw {
    cursor: default;
    background-color: transparent;
  }
  .input-append.date .add-on i,
  .input-prepend.date .add-on i {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  .input-daterange input {
    text-align: center;
  }
  .input-daterange input:first-child {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
  }
  .input-daterange input:last-child {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
  }
  .input-daterange .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 18px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 #ffffff;
    vertical-align: middle;
    background-color: #eeeeee;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
  }

  .bottom-aligned-items {
      display: flex;
      clear: both;
      align-items: flex-end;
      .right-side .form-group {
        text-align: right;
      }
      button {
          margin-bottom: .2em;
      }
  }

  .login-component {
      margin-bottom: 0;
  }





  .radio-group {
    label {
      margin-right: 2em;
      cursor: pointer;
    }
    input[type=radio] {
      margin-right: 0.5em;
    }
    :last-child {
      margin-right: 0;
    }
  }

  .form-group.form-button-container {
    text-align: right;
    margin: 1em 0;
    button {
      margin-bottom: 1em;
    }
    &.multiple-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      button {
        margin-right: 1em;
        &:last-of-type {
          margin-right: 0;
        }
      }
      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: space-evenly;
        button {
          margin-right: 0.5em;
          margin-left: 0.5em;
        }
      }
      &.no-bottom-margin {
        margin-bottom: 0;
        button {margin-bottom: 0;}
      }

    }
    &.with-status {
      justify-content: space-between;
      .status {
        flex-grow: 1;
        p {
          padding: 0;
          text-align: center;
          font-family: 'NunitoSans-SemiBold';
          color: #3f82ff;
          font-size: 1.0625em;
          font-style: italic;
          margin: 0;
        }
      }
    }

    &.sticky-bottom-buttons {
      // .scrolling
      position: sticky;
      bottom: 0;
      background: white;
      padding-top: 1em;
      margin-left: -3.75em;
      width: calc(100% + 7.5em);
      padding-right: 3.75em;
      box-shadow: 0px -5px 10px -5px #a1a1a182;
      z-index: 100000;
      transition: all .25s ease;
      .scrolled & {
        margin-left: 0;
        width: 100%;
        padding-right: 0;
        box-shadow: none;
        // transition: all .25s ease;
      }
    }
  }

  .demo-plus-button {
    margin-right: 1em;
    display: inline-block;
    width: auto;
    margin: auto;
    fa-icon {
      margin-right: 0.5em;
    }
  }

  .demo-minus-button {
    margin-top: 2em;
    @media screen and (max-width:800px) {
      margin-top: 0;
      margin-bottom: 2em !important;
    }
  }



  @-webkit-keyframes barrelRoll {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @-webkit-keyframes invertBarrelRoll {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(-360deg); }
  }

  @-ms-keyframes draw-around {
    0% {
      stroke-dashoffset: 640;
    }
    10% {
      stroke-dashoffset: 640;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes draw-around {
    0% {
      stroke-dashoffset: 640;
    }
    10% {
      stroke-dashoffset: 640;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes draw-around-one {
    0% {
      stroke-dashoffset: 640;
    }
    5% {
      stroke-dashoffset: 640;
    }
    35% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes draw-around-two {
    0% {
      stroke-dashoffset: 640;
    }
    35% {
      stroke-dashoffset: 640;
    }
    65% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes draw-around-three {
    0% {
      stroke-dashoffset: 640;
    }
    65% {
      stroke-dashoffset: 640;
    }
    95% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes draw-chart-one {
    0% {
      stroke-dashoffset: 300;
    }
    95% {
       stroke-dashoffset: 0;
     }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes draw-chart-two {
    0% {
      stroke-dashoffset: 640;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes draw-chart-three {
    0% {
      stroke-dashoffset: 640;
    }
    40% {
      stroke-dashoffset: 640;
    }
    75% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes draw-chart-tip {
    0% {
      stroke-dashoffset: 640;
    }
    70% {
      stroke-dashoffset: 640;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes move {
    0% {
      transform:rotate(-90deg);
    }

    30% {
      transform:rotate(90deg);
    }
    32% {
      transform:rotate(86deg);
    }
    34% {
      transform:rotate(88deg);
    }
    36% {
      transform:rotate(82deg);
    }
    38% {
      transform:rotate(86deg);
    }
    40% {
      transform:rotate(80deg);
    }
    42% {
      transform:rotate(88deg);
    }
    44% {
      transform:rotate(80deg);
    }
    46% {
      transform:rotate(82deg);
    }
    48% {
      transform:rotate(72deg);
    }
    50% {
      transform:rotate(80deg);
    }
    100% {
      transform:rotate(-90deg);
    }

  }
  .container.border-settings {
    padding: 0;
    width: 100%;
  }
  section#advantages {
    background-color: rgba(97, 64, 180, 0.05);  // temporary

    overflow: hidden;
    position: relative;
    padding-bottom: 150px;
    @media screen and (max-width: 1050px) {
      padding-bottom: 50px;
    }
    .inner {
      display: flex;
      flex-direction: column;
      height: 100%;

      header {
        flex-grow: 0;;

      }
      article {
        flex-grow: 1;
        position: relative;
      }
    }

    &:before {
      display: block;
    //content: " ";
    position: absolute;
    bottom: -5rem;
    left: -99rem;
    width: 400rem;
    z-index: 0;
    height: 117rem;
    background: #f7f5fb;
    transform: rotate(24deg);
    z-index: -1;
    }
    &:after {
      display: block;
      content: " ";
      position: absolute;
      bottom: -10rem;
      left: -131rem;
      width: 300rem;
      z-index: 0;
      height: 35rem;
      background: #ffffff;
      transform: rotate(18deg);
      @media screen and (max-width: 1050px) {
        bottom: -25rem;
        left: -131rem;
      }
    }


  }

  .advantages {
    padding: 0;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 800px;
    padding: 20px 0;
    justify-content: space-between;
    position: relative;


    //new design 1 + 2 + 1
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 1050px) {
      margin-top: -1em;
    }

    @media screen and (max-width: 950px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    @media screen and (max-width: 700px) {
      padding-bottom: calc(-10% + 180px);
    }

    li.break-flex-row {
      flex-basis: 100%;
      height: 0;
      list-style: none;
      @media screen and (max-width: 950px) {
        display: none;
      }
    }

    li.advantage {
      list-style: none;
      display: block;
      width: 25%;
      align-self: flex-start;
      // margin-bottom: 40px;
      // margin-top: 40px;
      box-sizing: content-box;
      position: relative;
      background-color: rgb(255, 255, 255);
      border-radius: 0;
      border: 5px solid #a58fde;
      padding: 10px 20px;
      box-shadow: 5px 5px 15px 0px rgba(95, 73, 123, 0.25);
      @media screen and (max-width: 1050px) {
        padding: 8px 16px;
        font-size: .9em;
      }
      @media screen and (max-width: 950px) {
        font-size: .8em;
      }
      @media screen and (max-width: 800px) {
        font-size: .7em;
        padding: 5px 15px;
        width: 30%;
      }
      @media screen and (max-width: 700px) {
        width: 200px;
      }
      @media screen and (max-width: 420px) {
        width: 100%;
        box-sizing: border-box;
        font-size: 0.65em;
      }

      h2 {
        font-size: 1.5em;
        span {
          display: inherit;
          text-align: right;
          &.big {
            font-size: 2em;
            font-family: 'NunitoSans';
            text-align: center;
            display: inherit;
          }

        }
      }

      &.advantage-automates {
        padding-left: 100px;
        margin-left: 10%;
        //width: 280px;

        //new design 1 + 2 + 1
        margin: 0 auto;
        @media screen and (max-width: 950px) {
          align-self: flex-start;
          margin: 0 0 0 10%;
        }
        @media screen and (max-width: 800px) {
          margin-left: 5%;
        }
        @media screen and (max-width: 700px) {
          margin-left: 2%;
        }
        @media screen and (max-width: 420px) {
          margin-left: auto;
          margin-right: auto;
          align-self: center;
        }

        h2 span.big {
          color: #ff6565;
          color: #ec5d5d;
        }
        .gear-icons {
          position: absolute;
          top: 50%;
          left: 10px;
          width: 80px;
          height: 80px;
          margin-top: -35px;
        }
        .icon-gear-one {
          display: block;
          position: absolute;
          width: 30px;
          height: 30px;
          top: 46px;
          left: 0px;

          //background: url(https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Gear_icon-72a7cf.svg/1024px-Gear_icon-72a7cf.svg.png);
          background: url(/app/vendor/images/blue-gear-icon.png);
          background-size: 30px 30px;
          -webkit-animation: barrelRoll 5s infinite linear;
          -webkit-animation-play-state: running;
        }
        .icon-gear-two {
          display: block;
          position: absolute;
          top: 0px;
          left: 14px;
          width: 60px;
          height: 60px;
          //background: url(https://svgsilh.com/svg_v2/306306-5a33c1.svg);
          background: url("/app/vendor/images/purple-gear-icon.svg");
          background-size: 60px 60px;
          -webkit-animation: barrelRoll 10s infinite linear;
          -webkit-animation-name: invertBarrelRoll;
        }
        &:after {
          // content: "";
          display: block;
          position: absolute;
          width: 180px;
          height: 320px;
          top: 173px;
          right: -6px;
          z-index: -1;
          transform: rotate(-30deg);
          background: url(/app/vendor/images/black-curved-arrow.jpg) transparent no-repeat;
          background-size: contain;
        }
      }

      &.advantage-speed {
        align-self: flex-end;
        margin-right: 0;
        padding-left: 100px;
        position: relative;

        //new design 1 + 2 + 1
        align-self: flex-start;

        @media screen and (max-width: 950px) {
          align-self: center;
        }

        h2 span.big {
          color: rgb(31, 199, 53);
          color: #199b2a;
        }
        &:after {
          //content: "";
          display: block;
          position: absolute;
          width: 192px;
          height: 241px;
          top: 198px;
          left: -33px;
          z-index: -4;
          transform: rotate(-30deg);
          transform: translateZ(180deg);
          background: url(/app/vendor/images/black-curved-arrow.jpg) transparent no-repeat;
          background-size: 100% 100%;
          transform: rotate(41deg)scaleX(-1);
        }
        .speed-icon {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 5px;
          margin-top: -42px;
          .speedometer {
            width: 60px;
            height: 60px;
            border-radius: 100%;
            border: 10px solid #ccc;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            -webkit-transform: rotate(45deg);
            display: inline-block;
            box-sizing: content-box;
          }
          .speedometer-dots {
            position: absolute;
            width: 48px;
            height: 46px;
            border-radius: 100%;
            border: 3px dotted #666;
            border-right: 3px dotted transparent;
            border-bottom: 3px dotted transparent;
            -webkit-transform: rotate(45deg);
            display: inline-block;
            box-sizing: content-box;
            top: 13px;
            left: 14px;
          }
          .needle {
            width: 3px;
            height: 28px;
            background: #5a33c1;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 100%;
            border-top-right-radius: 100%;
            display: inline-block;
            left: 39px;
            position: absolute;
            top: 12px;
            -webkit-animation: move 5s infinite;
            transform: rotate(0deg);
            transform-origin: bottom;
            animation-timing-function: ease-in-out;
          }
        }
      }
      &.advantage-errors {
        margin-left: 0;
        padding-left: 100px;

        @media screen and (max-width: 950px) {
          align-self: center;
        }
        h2 span.big {
          color: #1fc1ff;
          color: #039bd5;
        }
        .raport-icon {
          position: absolute;
          top: 50%;
          left: 15px;
          width: 70px;
          height: 80px;
          margin-top: -35px;
          border: 5px solid #b5b5b5;
          .lines-container {
            width: 25px;
            margin: 5px;
            position: absolute;
            top: -5px;
            left: 0;
            .lines {
              width: 25px;
              height: 20px;
              margin: 10px 0 5px;
              border-top: 5px solid #b5b5b5;
              border-bottom: 5px solid #b5b5b5;
            }
          }
          .checks-container {
            width: 25px;
            height: 61px;
            position: absolute;
            top: 5px;
            right: 0px;
            #checkmark1 {
              stroke-dasharray: 640;
              stroke-dashoffset: 0;
              -webkit-transform-origin: center;
              -ms-transform-origin: center;
              transform-origin: center;
              -webkit-animation: draw-around-one 6s infinite;
              -ms-animation: draw-around-one 6s infinite;
              animation: draw-around-one 6s infinite;
            }
            #checkmark2 {
              stroke-dasharray: 640;
              stroke-dashoffset: 0;
              -webkit-transform-origin: center;
              -ms-transform-origin: center;
              transform-origin: center;
              -webkit-animation: draw-around-two 6s infinite;
              -ms-animation: draw-around-two 6s infinite;
              animation: draw-around-two 6s infinite;
            }
            #checkmark3 {
              stroke-dasharray: 640;
              stroke-dashoffset: 0;
              -webkit-transform-origin: center;
              -ms-transform-origin: center;
              transform-origin: center;

              -webkit-animation: draw-around-three 6s 2s infinite;
              -ms-animation: draw-around-three 6s infinite;
              animation: draw-around-three 6s infinite;
            }
          }
        }
        &:after {
          //content: "";
          display: block;
          position: absolute;
          width: 180px;
          height: 320px;
          top: 200px;
          right: -6px;
          z-index: -1;
          transform: rotate(-30deg);
          background: url(/app/vendor/images/black-curved-arrow.jpg) transparent no-repeat;
          background-size: contain;
        }
      }
      &.advantage-business {
        align-self: flex-end;
        margin-right: 10%;
        padding-left: 100px;

        //new design 1 + 2 + 1
        margin: 0 auto;
        align-self: flex-start;

        @media screen and (max-width: 950px) {
          align-self: flex-end;
          margin-right: 10%;
        }
        @media screen and (max-width: 800px) {
          margin-right: 5%;
        }
        @media screen and (max-width: 700px) {
          margin-right: 2%;
        }
        @media screen and (max-width: 420px) {
          margin-left: auto;
          margin-right: auto;
          align-self: center;
        }

        h2 span.big {
          color: #f970ee;
          color: #ed47e0;
        }
        .growth-icon {
          position: absolute;
          top: 50%;
          left: 5px;
          width: 90px;
          height: 70px;
          margin-top: -35px;
          border: 5px solid #b5b5b5;
          border-top: none;
          border-right: none;
          .line {
            position: absolute;
            top: 6px;
            left: 3px;

            #chart-line1 {
              stroke-dasharray: 300;
              stroke-dashoffset: 0;
              -webkit-transform-origin: inherit;
              -ms-transform-origin: inherit;
              transform-origin: inherit;
              -webkit-animation: draw-chart-one 3s infinite linear;
              -ms-animation: draw-chart-one 3s infinite linear;
              animation: draw-chart-one 3s infinite  linear;
            }
            #chart-line2 {
              stroke-dasharray: 640;
              stroke-dashoffset: 640;
              -webkit-transform-origin: inherit;
              -ms-transform-origin: inherit;
              transform-origin: inherit;
              -webkit-animation: draw-chart-two 6s infinite;
              -ms-animation: draw-chart-two 6s infinite;
              animation: draw-chart-two 6s infinite;
            }
            #chart-line3 {
              stroke-dasharray: 640;
              stroke-dashoffset: 640;
              -webkit-transform-origin: inherit;
              -ms-transform-origin: inherit;
              transform-origin: inherit;
              -webkit-animation: draw-chart-three 6s infinite;
              -ms-animation: draw-chart-three 6s infinite;
              animation: draw-chart-three 6s infinite;
            }
            #chart-line4 {
              stroke-dasharray: 640;
              stroke-dashoffset: 640;
              -webkit-transform-origin: inherit;
              -ms-transform-origin: inherit;
              transform-origin: inherit;
              -webkit-animation: draw-chart-tip 6s infinite;
              -ms-animation: draw-chart-tip 6s infinite;
              animation: draw-chart-tip 6s infinite;
            }
            #chart-line5 {
              stroke-dasharray: 640;
              stroke-dashoffset: 640;
              -webkit-transform-origin: inherit;
              -ms-transform-origin: inherit;
              transform-origin: inherit;
              -webkit-animation: draw-chart-tip 6s infinite;
              -ms-animation: draw-chart-tip 6s infinite;
              animation: draw-chart-tip 6s infinite;
            }
          }
        }
      }

      span.icon {
        display: block;
      }
    }
    li.arrow-one {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      width: 180px;
      height: 20%;
      top: calc(2% + 184px);
      left: 26%;
      z-index: -1;
      transform: rotate(-30deg);
      background: url(/app/vendor/images/black-curved-arrow.jpg) transparent no-repeat;
      background-size: 100% 100%;
      box-shadow: none;
    }
    li.arrow-two {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      width: 180px;
      height: 22%;
      top: calc(28% + 184px);
      right: 28%;
      z-index: -1;
      background: url(/app/vendor/images/black-curved-arrow.jpg) transparent no-repeat;
      background-size: 100% 100%;
      box-shadow: none;
      transform: rotate(41deg) scaleX(-1);
    }
    li.arrow-three {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      width: 180px;
      height: 20%;
      top: calc(55% + 184px);
      left: 20%;
      z-index: -1;
      transform: rotate(-30deg);
      background: url(/app/vendor/images/black-curved-arrow.jpg) transparent no-repeat;
      background-size: 100% 100%;
      box-shadow: none;
    }
    li.arrow-four {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      width: 20%;
      height: 28%;
      top: calc(-1% + -6px);
      right: 29%;
      z-index: -1;
      background: url(/app/vendor/images/black-curved-arrow.jpg) transparent no-repeat;
      background-size: 100% 100%;
      box-shadow: none;
      transform: rotate(-60deg) scaleX(-1);

      //new design 1 + 2 + 1
      top: calc(0% + 40px);
      left: calc(50% + 225px);
      transform: rotate(-45deg) scaleX(-1);

      @media screen and (max-width: 950px) {
        display: none;
      }
    }
    li.arrow-six {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      width: 20%;
      height: 28%;
      top: calc(48% + -6px);
      right: 39%;
      z-index: -1;
      background: url(/app/vendor/images/black-curved-arrow.jpg) transparent no-repeat;
      background-size: 100% 100%;
      box-shadow: none;
      transform: rotate(-60deg) scaleX(-1);

      //new design 1 + 2 + 1
      bottom: calc(0% + 100px);
      top: auto;
      left: calc(50% + 225px);
      transform: rotate(45deg) scaleX(-1);
      @media screen and (max-width: 950px) {
        bottom: calc(0% + 192px);
        left: calc(50% + 194px);
        transform: rotate(-25deg) scaleX(-1);
      }
      @media screen and (max-width: 800px) {
        transform: rotate(-20deg) scaleX(-1);
        width: 18%;
        height: 25%;
        bottom: calc(0% + 220px);
        left: auto;
        right: 5%;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    li.arrow-five {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      width: 20%;
      height: 28%;
      top: calc(20% + 15px);
      left: 36%;
      z-index: -1;
      transform: rotate(56deg);
      background: url(/app/vendor/images/black-curved-arrow.jpg) transparent no-repeat;
      background-size: 100% 100%;
      box-shadow: none;

      //new design 1 + 2 + 1
      left: auto;
      top: calc(0% + 40px);
      right: calc(50% + 225px);
      transform: rotate(45deg);
      @media screen and (max-width: 950px) {
        top: calc(0% + 192px);
        right: calc(50% + 194px);
        transform: rotate(-35deg);
      }
      @media screen and (max-width: 800px) {
        transform: rotate(-30deg);
        width: 18%;
        height: 25%;
        top: calc(0% + 220px);
        right: auto;
        left: 5%;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    li.arrow-seven {
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      width: 20%;
      height: 28%;
      bottom: calc(0% + 100px);
      right: calc(50% + 225px);
      transform: rotate(-45deg);
      z-index: -1;
      background: url(/app/vendor/images/black-curved-arrow.jpg) transparent no-repeat;
      background-size: 100% 100%;
      box-shadow: none;

      @media screen and (max-width: 950px) {
        display: none;
      }
    }
  }

  h2.rest-logo {
    font-size: 5em;
    font-family: sans-serif;
    font-weight: 600;
    float: left;
    span {
      font-size: 1.6em;
      color: #a58fde;
      font-family: sans-serif;
      font-weight: 100;
      margin-top: -.28em;
      display: block;
      float: left;
      margin-right: .05em;
      &:last-child {
        float: right;
        margin-top: -.28em;
        margin-left: .1em;
      }
    }
  }

  .api-contract {
    font-size: 5em;
    font-family: 'NunitoSans';
    padding: 1em;
    display: block;
    margin: 20px;
    /* clear: both; */
    //background: url(https://svgsilh.com/svg_v2/306306-5a33c1.svg);
    background: url("/app/vendor/images/purple-gear-icon.svg");
    background-size: 100%;
    display: inline-block;
    position: relative;
    &:before {
      content: "\f061";
      display: inline-block;
      position: absolute;
      font-family: fontawesome;
    }
    &:after {
      content: "\f060";
      font-family: fontawesome;
      display: inline-block;
      position: absolute;
    }
  }

// HOMEPAGE SECTIONS

// GENERAL CLASSES

  section.homepage-section {
    header {
      h2 {
        font-family: 'NunitoSans';
        font-size: 4em;
        text-align: center;
        padding: 1em 0;
        text-shadow: 2px 2px 4px rgba(95, 73, 123, 0.25);
        @media screen and (max-width: 1050px) {
          font-size: 3.5em;
        }
        @media screen and (max-width: 800px) {
          font-size: 3em;
        }
        @media screen and (max-width: 700px) {
          font-size: 2.5em;
        }
        @media screen and (max-width: 550px) {
          font-size: 2em;
        }
      }
    }
    article {
      p {
        font-size: 1.75em;
        @media screen and (max-width: 1050px) {
          font-size: 1.6em;
        }
        @media screen and (max-width: 800px) {
          font-size: 1.5em;
        }
        @media screen and (max-width: 550px) {
          font-size: 1.25em;
        }
      }
    }
  }




section#sign-up {
  article {
    header {
      h2 {

        padding-bottom: 10px;
      }
    }
    p {
      font-size: 1.5em;
    }
    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 2em 0;
      flex-wrap: wrap;
      li {
        display: block;
        list-style: none;
        width: 25%;
        text-align: center;

        display: flex;
        list-style: none;
        width: 25%;
        text-align: center;
        background-color: #f9f9f9;
        border-radius: 0;
        // border: 5px solid #a58fde;
        padding: 10px 20px;
        flex-direction: column;
        box-shadow: 5px 5px 15px 0px rgba(95, 73, 123, 0.25);
        justify-content: space-around;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        min-width: 250px;
        margin: 5% 2%;
        height: 200px;

        div {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}

.social-nav.has-user-warning {
  margin-top: 31px;
  @media screen and (max-width: 700px) {
    margin-top: 56px;
  }
  @media screen and (max-width: 400px) {
    margin-top: 49px;
  }
}

.user-warning {
  width: 100%;
  color: #f7f5fb;
  background: #9370db;
  font-family: "NunitoSans";
  border: 2px solid $purple-primary;
  box-shadow: 0 0 25px 0 $purple-primary;
  z-index: 11;
  position: fixed;
  top: 0;
  .alert-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .25em 0;
    max-width: 1170px;
    margin: 0 auto;
    p {
      margin: 0;
      padding: 0 2em;
      color: #ffffff;
      &:first-child {
        padding-left: 1.1em;
      }
      &:last-child {
        padding-right: 1.1em;
      }
    }
    .statistics {
      display: flex;
    }
    @media screen and (max-width: 700px) {
      flex-direction: column;
      p {
        padding: 0;
        text-align: center;
      }
      .statistics {
        display: flex;
        justify-content: space-evenly;
      }
    }
    @media screen and (max-width: 400px) {
      p, .statistics {
        font-size: 0.875em;
      }
    }
  }
}

.file-input-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1em;
  label {
    flex-shrink: 0;
  }
  .file-selected {
    font-size: 1em;
    flex-grow: 1;
    display: block;
    padding: 0.5em;
    font-family: "NunitoSans";
    background: #f8f5fc;
    position: relative;
    line-height: 1;
    button.clear {
      width: 1em;
      height: 1em;
      text-indent: -100000em;
      position: absolute;
      color: $purple-hover-light;
      overflow: visible;
      background: none;
      border: none;
      right: 1em;
      top: 0.1em;
      &:hover {
        color: $purple-active-dark;
      }
      &:before {
        content: "\f00d";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.5em;
        text-indent: 0;
        font-family: FontAwesome;
      }
    }
  }
}


.generator-form {
  .flex-columns, .row {
    margin-top: 2em;
    &:first-child {
      margin-top: 0;
    }
  }
}

.inline-links-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;
  li {
    list-style: none;
    display: inline-block;
    flex-grow: 1;
    text-align: center !important;
    &:first-child {
      text-align: left !important;
    }
    &:last-child {
      text-align: right !important;
      border: none;
    }
  }
}

  //@import "../../components/signup/signup.scss";


input, .form-control,
.fade:not(.in) .form-control {
  &.ng-invalid.ng-dirty.ng-touched {
    // box-shadow: 0 0 0px 2px rgba(255,0,0,0.45);
    &:focus {
      //box-shadow: none;
    }
  }
}
.fade .form-control.ng-invalid.ng-dirty.ng-touched {
  box-shadow: none;
}

.fade.in .form-control.ng-invalid.ng-dirty.ng-touched {
  // box-shadow: 0 0 0px 2px rgba(255,0,0,0.45);
}

.inline-field-errors {
  width: 100%;
  margin-top: .25rem;
  padding-left: .25em;
  display: block;
  font-size: 80%;
  color: #ff4949;
  &.password-validator {
    background-color: white;
    border: 1px solid #acacac85;
    top: 1.75em;
    left: -2em;
    color: black;
    text-shadow: none;
    &:after {
      border-right: 6px solid #acacac85;
    }
    span {
      display: block;
      text-align: left;
    }
  }
}

.form-field-error {
  width: 100%;
  margin-top: .25rem;
  text-indent: .25em;
  display: block;
  font-size: 80%;
  color: #dc3545;
  color: #ff4949;
}

.ng-select .ng-select-container {
  border-radius: 0;
}

.ng-select.is-invalid {
  padding-right: 0;
  background: none;
}
.ng-select.is-invalid .ng-select-container {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}
.ng-select.is-invalid.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}



.modal-flex-middle {
  height: 80%;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#userDropdown {
  & > a {
    font-size: 16px;
    text-transform: none;
    /* padding-top: 5px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    i {
      color: #72d142;
      /* border: 28px inset limegreen; */
      border-radius: 100%;
      /* background: limegreen; */
      padding: 0 1px 1px 0;
      text-indent: 0px;
      font-size: 20px;
      /* border-style: dashed; */
      margin-top: 0px;
      margin-right: 5px;
    }
    span {
      display: block;
      /* line-height: 28px; */
      margin-right: 3px;
    }
    span.caret {
      margin-right: 0;
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid\9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }


  ul {
    padding: 0.75em 1em;
    font-size: 1em;
    li {
      width: 100%;
      margin-bottom: 0.25em;
      &.bottom-border {
        border-bottom: 1px solid rgba(117, 117, 117, 0.219);
        margin-bottom: 0.75em;
        padding-bottom: 1em;
      }
      span {
        font-weight: bold;
        margin-left: .25em;
        text-transform: lowercase;
      }
      a {
        i {
          margin-right: .25em;
          font-size: 1.25em;
        }
      }
    }
  }
}

h2.error {
  color: #dc3545;
}
h2.success {
  color: #72d142;
}


.icon-in-button {
  padding-right: .5em;
}
section.page-section > .inner article ul.pagination-buttons li {
  clear: none;
}

.search-sec {
  input[type=search] {
    min-width: 300px;
  }
  button {
    position: absolute;
    right: 0;
    top: 0.2em;
  }
}

.filter-holder {
  float: left;
}

.downloads-table table {
  font-size: 0.9375em;
  tr {
    opacity: 1;
    td {
      padding: 8px 8px;
    }
    &.ng-enter {
      -webkit-transition: all .25s ease;
      transition: all .25s ease;
      opacity: 0;


    }
    &.ng-enter-active {
      opacity: 1;
      td {
        padding: 8px 8px;
      }

    }
    &.ng-enter-stagger {
      /* this will have a 100ms delay between each successive enter animation */
      -webkit-transition-delay: 0.05s;
      transition-delay: 0.05s;

      /* in case the stagger doesn't work then these two values
       must be set to 0 to avoid an accidental CSS inheritance */
      -webkit-transition-duration: 0s;
      transition-duration: 0s;

    }
  }
}



.flex-article-aside {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  aside {
    width: 180px;
    /* max-width: 300px; */
    text-align: center;
    flex-shrink: 0;
    flex-grow: 0;
    nav {
      a {
        display: flex;
        min-height: 3em;
      }
    }
  }
  article {
    padding-left: 2.5%;
    padding-right: 2.5%;
    border: 2px solid #f4edfe;
    flex-grow: 1;
  }
}

.account-profile-settings-table {
  margin-bottom: 2em;
}

label {
  font-family: 'calibri';
  font-size: 1.0625em;
  font-weight: normal;
}

label.required,
label > span.required {
  position: relative;
  // padding-left: 0.85em;
  &:before {
    font-weight: 100;
    font-size: 1.25em;
    line-height: 1;
    content: "*";
    font-family: monospace;
    position: relative;
    left: 0;
    top: -.35em;
    top: 0;
    color: #ff4949;
    display: inline-block;
  }
}

label > input[type="checkbox"] ~ span.required {
  padding-left: .9em;
}



.no-top-border {
  border-top: none !important;
}

.ng-hide{
  display: none !important;
}


table.small-table {
  font-size: 0.875em;
  thead tr {
    background-color: #f5f5f5;
  }
  th, td {
    padding: 0.5em;
  }
}

#users-table {
  width: 100%;
  box-shadow: 2px 2px 15px 0 rgba($color: #000000, $alpha: .2);
  td, th {
    border: 1px solid rgba($color: #000000, $alpha: .05);

    i {
      font-size: 1.5em;
      margin-right: 0.25em;
    }
  }
  .filters-dropdown {
    padding: 1em;
    width: 300px;
    li, p {
      font-size: 16px;

    }
  }
}



// TODO: Move grid table css to list component

.grid-table {
  // box-shadow: 2px 2px 15px 0 rgba($color: #000000, $alpha: .2);
  //display: grid;
  border-collapse: collapse;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
  table-layout: fixed;
  flex: 1;
  border: 1px solid #ddd;
  margin-bottom: 20px;

    thead,
    tbody,
    tr {
      //display: contents;

    }
    tr:hover {
      outline: 2px #00000045 solid;
      & > td {
        z-index: 2;
      }
    }



    td {
      padding: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: all .25s ease;
      &:hover {
        overflow: visible;
        white-space: nowrap;
        span {
          // background: snow;
          // display: inline-block;
          z-index: 111;
          position: relative;
          // padding: 0px 5px 0 0;
        }
      }
      &.centered {
        text-align: center;
        justify-content: center;
      }
    }

    th {
      padding: 8px;
      position: sticky;
      align-items: center;
      top: 0;
      background: #f6e7ff;
      background: #f5f5f5;
      text-align: left;
      font-weight: normal;
      white-space: normal;
      word-wrap: normal;
      border-right: 1px solid rgba(97, 64, 180, 0.2);
      border-bottom: 1px solid #999999;
      &.one-button {
        width: 55px;
        // max-width: 70px;
      }
      &.two-buttons {
        width: 120px;
        max-width: 120px;
      }
      // @media screen and (max-width: 800px) {
      //   font-size: 12px;
      // }
      // @media screen and (max-width: 700px) {
      //   font-size: 10px;
      // }
      &:last-of-type {
        border-right: none;
      }
      &.merge-cells {
        overflow: visible;
        padding-top: 35px;
        text-align: center;
        position: relative;
        span {
          position: absolute;
          background: #f6e7ff;
          top: 0;
          left: 0;
          width: 200%;
          text-align: center;
          z-index: 1;
          padding: 8px 5px 3px;
        }
      }
      &.with-filters, &.with-sorting {
        span.column-title {
          padding-right: 2em;
        }
        & > i {
          position: absolute;
          top: calc(50% - 0.5em);
          right: .25em;
        }
        .sort-button {
          position: absolute;
          top: calc(50% - 0.75em);
          right: .3em;
        }
      }
      &.with-filters-and-sorting {
        .two-icons {
          padding-right: 2.5em;
        }
        span.column-title {
        }
        & > i, div.two-icons > i {
          position: absolute;
          bottom: calc(50% - 0.5em);
          right: .25em;
        }
        .sort-button {
          position: absolute;
          top: calc(50% - 0.75em);
          right: 1.5em;
        }
      }
      & > i, div.two-icons > i {
        font-size: 1.25em;
        color: #c1c1c1;
        padding-left: 5px;
        cursor: pointer;
        &.active {
          color: #6140b4;
        }
        &:hover {
          opacity: .5;
          text-shadow: 2px 2px 5px #705f7d82;
        }
      }
      & > .sort-button, div.two-icons > .sort-button {
        font-size: 1.25em;
        color: #c1c1c1;
        padding-left: 5px;
        cursor: pointer;
        .fa-sort-desc,
        .fa-sort-asc {
          position: absolute;
          color: #6140b4;
          left: 5px;
          opacity: 0;
          transition: all .5s ease;
        }
        .fa-sort-desc {
          bottom: 5px;
        }
        .fa-sort-asc {
          top: 5px;
          height: 11px;
          z-index: 20;
          &:before {
            height: 11px;
            display: block;
          }
        }
        &.sort-desc .fa-sort-desc {
          opacity: 1;
        }
        &.sort-asc .fa-sort-asc {
          opacity: 1;
        }
      }
    }


    td {
      padding: 8px;
      color: #808080;
      align-items: center;
      // background: white;
      border-right: 1px solid rgba(97, 64, 180, 0.2);
      button {
        width: 30px;
        height: 30px;
        line-height: 26px;
        border-width: 1px;
      }
      &.narrow {
        width: 50px;
        min-width: 50px;
      }
      &:last-of-type {
        border-right: none;
      }
      &.active-status:before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin-right: .5em;
      }
      &.active-status.active:before {
        background-color: green;
      }
      &.active-status.expiring:before {
        background-color: orange;
      }
      &.active-status.expired:before {
        background-color: red;
      }
      &.number-of-subusers {
        span {
          height: 30px;
          line-height: 30px;
        }
        button {
          transition: all 0.25s ease;
          &.open {
            transform: rotate(180deg);
          }
        }

      }
    }

    tbody:nth-child(even) tr td {
      // background: #f8f6ff63;
    }

    .resize-handle {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background: black;
      opacity: 0;
      width: 3px;
      cursor: col-resize;
    }
    .resize-handle:hover,
    /* The following selector is needed so the handle is visible during resize even if the mouse isn't over the handle anymore */
    .header--being-resized .resize-handle {
      opacity: 0.5;
    }
    th:hover .resize-handle {
      opacity: 0.3;
    }
}

.sorting-dropdown {
  min-width: 220px;
}

.users-per-page-dropdown {
  min-width: 75px;
}

ul.pagination-buttons-new {
  margin-right: 0;
  display: flex;
  padding: 0;
  li {
    padding: 0;
    cursor: pointer;
    span, i {
      padding: 5px 15px 5px 15px;
      display: block;
      line-height: 1.5;
    }
    &.active {
      background-color: #6140b4 !important;
      color: #fff;
      &:hover {
        background-color: #6140b4 !important;
      color: #fff;
      //opacity: .8;
      cursor: default;
      }
    }
    &.dots:hover {
      cursor: default;
      background: white;
    }
    &.disabled {
      background-color: #a7a5ae;
      color: white;
      cursor: default;
      &:hover {
        background-color: #a7a5ae;
        color: white;
        cursor: default;
      }
    }
  }
}

section.get-demo {
  .faq-question {
    text-align: left;
    font-family: "NunitoSans-LightItalic";
    font-size: 1.75em;
    margin-bottom: 0.25em;
    margin-left: 40px;
    @media screen and (max-width: 550px){
      font-size: 1.5em;
    }
    &:before {
      content: "Q:";
      content: "\f0e5";
      color: #f09450;
      font-family: FontAwesome;
      margin-left: -35px;
      font-size: 30px;
      width: 35px;
      display: inline-block;
      transform: scaleX(-1);
      text-align: left;
      margin-right: 10px;
    }
  }

  .faq-answer {
    text-align: left;
    font-size: 1.5em;
    margin-left: 80px;
    @media screen and (max-width: 550px) {
      font-size: 1.25em;
    }
  }
}

.left-aligned-buttons {
  margin-top: 2em;
  text-align: left;
  & > div {
    display: inline-block;
    margin-right: 2em;
    &:last-child {
      margin-right: 0;
    }
  }
}

.video-placeholder {
  width: 100%;
  max-width: 380px;
  margin: 2em auto;
  box-shadow: 0 0 25px 0 rgba(147, 112, 219, 0.2);
  img {
    width: 100%;
  }
}



.re-captcha-cotainer {
  float: right;
  // margin-bottom: 0;
  // margin-top: 15px;
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform:translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

@-webkit-keyframes slideOut {
  0% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
  0% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
}


.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
// .open>.slideIn {
//   -webkit-animation-name: slideOut;
//   animation-name: slideOut;
// }

.dropdown-menu:not(typeahead-container) {
  &.wide {
    min-width: 200px;
  }
}
.dropdown-inner {
  padding: 0 20px 20px;
}



.table-legend-top {
  margin-top: 20px;
  & > div:not(:last-child) {
    @media screen and (min-width: 801px) {
      margin-right: 2em;
    }

  }
}


// ###############################################
// TODO: GDPR component will include css for it

gdpr-consent {
  position: fixed;
  position: sticky;
  bottom: 0;
  z-index: 10000;
  transition: all 0.5s ease;
  &.initial-hide {
    transform: translate(0, 300px);
  }
}
.gdpr-popup {
  position: relative;
  background-color: #f7f5fb;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  box-shadow: 0 -10px 15px -10px rgb(105, 98, 111);
  z-index: 100;
  .inner {
    padding: 0 50px;
    position: relative;
    .close {
      z-index: 1;
      position: absolute;
      top: -0.75em;
      right: .25em;
      font-size: 1.52em;
      transition: all .25s ease;
      padding: .25em;
    }
    .gdpr-buttons {
      width: auto;
      flex-shrink: 0;
      margin-left: 2em;
      button:first-child {
        display: block;
        width: 100%;
        margin-bottom: 1em;
      }
      @media screen and (max-width: 800px) {
        margin-top: 1em;
        display: block;
        text-align: center;
        margin-left: 0;
        button:first-child {
          width: auto;
          display: inline-block;
          margin-bottom: 0;
          margin-left: 2em;
        }
      }
      @media screen and (max-width: 550px) {
        button {
          display: block;
          margin: 0 auto;
        }
        button:first-child {
          display: block;
          margin: 0 auto 1em;
        }
      }
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 1.15em;
    color: black;
    @media screen and (max-width: 440px) {
      font-size: 1em;
    }
  }
}


#gdprDetails {
  form-field {
    width: auto;
    flex-shrink: 0;
    margin-left: 2em;
  }
  .gdpr-buttons {
    width: auto;
    flex-shrink: 0;
  }
  .flex-columns > div,
  .fkex-columns > form-field {
    margin-bottom: 1em;
    & > p:last-of-type {
      margin-bottom: 0;
    }
  }
  .small-table {
    margin-bottom: 0;
  }
  .collapse-button {
    padding-left: 0;
  }
}
// ###############################################



// switches
//
// Tweak a few things for switches

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;

  background-color: #6140b4;
  border-color: #51388e;

  background-color: #77a7ff;
  border-color: #3f82ff;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2em rgba(0,123,255,.25);
  // box-shadow: 0 0 0 0.2em rgba(97, 64, 180, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  background-color: #8a61ef;
  border-color: #b3d7ff;

  border-color: #6140b4;
  box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.4);

  background-color: #77a7ff;
  border-color: #3f82ff;
}

.custom-control-input:disabled~.custom-control-label {
  color: #6c757d;
  cursor: default;
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-size: 16px;
  cursor: pointer;
}

.custom-control-label::before {
  position: absolute;
  top: .25em;
  left: -1.5em;
  display: block;
  width: 1em;
  height: 1em;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
  cursor: pointer;
}
.right-sided .custom-control-label::before {
  left: auto;
  right: -1.5em;
}

.custom-control-label::after {
  position: absolute;
  top: .25em;
  left: -1.5em;
  display: block;
  width: 1em;
  height: 1em;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  .right-sided & {
    left: auto;
    right: -1.5em;
  }
}




.custom-switch {
  padding-left: 3em;
  &.right-sided {
    padding-left: 0;
    padding-right: 3em;
  }
}

.custom-switch .custom-control-label::before {
  left: -2.25em;
  width: 1.75em;
  pointer-events: all;
  border-radius: .5em;
}
.right-sided.custom-switch .custom-control-label::before {
  left: auto;
  right: -2.25em;
}

.custom-switch .custom-control-label::after {
  top: calc(.25em + 2px);
  left: calc(-2.25em + 2px);
  width: calc(1em - 4px);
  height: calc(1em - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
}

.right-sided.custom-switch .custom-control-label::after {
  left: auto;
  right: calc(-1.5em + 2px);
}

@media screen and (prefers-reduced-motion:reduce) {
  .custom-switch .custom-control-label::after {
      transition: none;
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(.75em);
  transform: translateX(.75em);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0,123,255,.5);
  background-color: rgba(97, 64, 180, 0.5);
}



//  Skit to main content button
#btn-skip-nav {
  width: 100%;
  text-align: center;
  background: #f9f9f9;
  padding: 0;
  a {
    color: #6140b4;
    font-size: 1.25em;
    padding: 0;
    transition: all 0.25s ease-in-out;
    left: -999em;
    opacity: 0;
    display: inline-block;
    position: absolute;
    &:focus {
      padding: 5px 10px;
      margin: 5px 0;
      opacity: 1;
      position: static;
      outline-color: #6140b4;
      outline-style: auto;
      outline-width: 5px;
      outline-offset: -2px;
    }
  }
}



/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 400px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog{
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
       -o-transition: opacity 0.3s linear, left 0.3s ease-out;
          transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog{
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
       -o-transition: opacity 0.3s linear, right 0.3s ease-out;
          transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}



// Accordion Styling
.panel-group .panel {
  .ng-fa-icon {
    float: left;
    transition: all .25s ease-in-out;
    transform: rotate(0);
    margin-right: 1em;
  }
  &.panel-open {
    .ng-fa-icon {
      transform: rotate(90deg);
    }
  }
}

.form-group {
  position: relative;
}

// ####################################################
// TODO: compare and move to form-field or file upload
.errors-container {
  position: absolute;
  right: calc(100% + 10px);
  line-height: 1;
  z-index: 100000;
  bottom: 7px;
  width: max-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  max-width: 200px;
  &.short {
    width: fit-content;
  }
  .inline-field-errors {
    letter-spacing: 0.5px;
    width: fit-content;
    color: white;
    background: #d30707;
    padding: 7px;
    border-radius: 0px;
    font-family: "NunitoSans-Light";
    text-shadow: 0px 0px 2px #9d0707;
    font-size: 14px;
    margin-top: 0;
    position: relative;
    margin-bottom: 1px;
    transition: all .2s ease;
    text-align: right;
    float: right;
    right: 0;
    clear: right;
    &:after {
      position: absolute;
      content: " ";
      display: block;
      right: -9px;
      top: calc(50% - 4px);
      width: 9px;
      height: 10px;
      z-index: 100000;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #d30707;
    }
    &.password-validator {
      background-color: white;
      border: 1px solid #acacac85;
      top: 1.75em;
      left: -2em;
      color: black;
      text-shadow: none;
      &:after {
        border-right: 6px solid #acacac85;
      }
      span {
        display: block;
        text-align: left;
      }
    }


  }
  &.right-side-errors {
    right: auto;
    left: calc(100% + 40px);
    .inline-field-errors {
      &:after {
        right: auto;
        left: -9px;
        border-left: none;
        border-right: 6px solid #d30707;
      }
    }
    @media screen and (max-width: 800px) {
      right: auto;
      left: auto;
      bottom: auto;
      background: #d30707;
      align-items: stretch;
      margin-top: 10px;
      .inline-field-errors {
        width: auto;
        text-align: center;
        &:after {
          content: none;
        }
        &:first-of-type:after {
          position: absolute;
          content: " ";
          display: block;
          top: -12px;
          right: calc(50% - 4px);
          width: 9px;
          height: 10px;
          z-index: 100000;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #d30707;
        }
      }
    }
  }
  .narrow-side-menu & {
    right: auto;
    left: auto;
    bottom: auto;
    background: #d30707;
    align-items: stretch;
    margin-top: 10px;

    .inline-field-errors {
      width: auto;
      text-align: center;
      &:after {
        content: none;
      }
      &:first-of-type:after {
        position: absolute;
        content: " ";
        display: block;
        top: -12px;
        right: calc(50% - 4px);
        width: 9px;
        height: 10px;
        z-index: 100000;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #d30707;
      }

    }
  }
  @media screen and (max-width: 800px) {
    right: auto;
    left: auto;
    bottom: auto;
    background: #d30707;
    align-items: stretch;
    margin-top: 10px;

    .inline-field-errors {
      width: auto;
      text-align: center;
      &:after {
        content: none;
      }
      &:first-of-type:after {
        position: absolute;
        content: " ";
        display: block;
        top: -12px;
        right: calc(50% - 4px);
        left: auto;
        width: 9px;
        height: 10px;
        z-index: 100000;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #d30707;
      }
    }
  }

}

.input-tooltip-icon {
  color: #77a7ff;
  font-size: 1.125em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: inline-block;
  margin-top: -0.125em;
  cursor: pointer;
}

.input-tooltip {
  z-index: 10000000 !important;
  border-radius: 0 !important;
  font-size: 0.9125rem !important;
  padding: 6px 10px !important;
  letter-spacing: 0.5px !important;
}

.input-instruction {
  font-family: 'NunitoSans-LightItalic';
  font-size: 0.875em;
  letter-spacing: 0.5px;
  margin-bottom: 0.25em;
  &.instruction-below-input {
    color: #adb5bd;
    margin-bottom: 0;
    font-size: 0.75em;
    padding-left: 0.25em;
  }
}

.form-group {
  margin-bottom: 3.5em;
  @media screen and (max-width: 800px) {
    margin-bottom: 2.75em;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 2em;
  }
}

// ################################

@keyframes backdrop-show {
  0% {
    display: none;
    background-color: #00000000;
  }
  1% {
    display: block;
    background-color: #00000000;
  }
  100% {
    display: block;
    background-color: #000000bf;
  }
}

@keyframes opacityAnimation {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

.side-popup-container {
  transform-origin: right center;
  .side-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    height: 100%;
    // background-color: #00000070;
    animation: backdrop-show 0.5s ease 1s;
    animation-fill-mode: forwards;
    // z-index: -1;
  }

  .scrollable-fields-container {
    opacity: 0;
    animation: opacityAnimation 0.5s ease 1s;
    animation-fill-mode: forwards;
    flex-grow: 1;
  }

  .form-group.bottom-absolute {
    opacity: 0;
    animation: opacityAnimation 0.5s ease 1s;
    animation-fill-mode: forwards;
  }

  .side-inner {
    position: absolute;
    z-index: 1000000;
    top: 0;
    right: 0;
    width: 80vw;
    height: 100%;
    overflow: auto;
    transform-origin: right center;
    padding: 20px;
    border-left: 1px solid #e0e0e0;
    padding-top: 25px;

    // new settings
    background-color: transparent;
    border: none;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    overflow: visible;
    padding: 0;

    .close-side-popup, .close-popup-button {
      position: absolute;
      top: 1%;
      right: 2%;
      z-index: 1000;
      @media screen and (max-width: 800px) {
        right: 5%;
      }
    }
    lib-ng-quote,
    lib-ng-demo,
    lib-ng-quote-new,
    lib-support-request-form,
    app-new-license-form {
      width: 100%;
      height: 100%;
      overflow: auto scroll;
      top: 0;
      right: 0;
      position: relative;
      display: block;
      width: 100vw;
      left: auto;
      margin: auto;
    }

    form {
      // width: calc(100% - 300px);
      right: 0;
      position: relative;
      margin: 0 auto;
      max-width: 1000px;

      right: 0;
      position: relative;
      margin: 0 0 0 auto;
      max-width: 1000px;
      background: white;
      width: 80%;
      min-height: 100vh;

      display: flex;
      flex-direction: column;
    }
  }

  .documentation-side-inner {
    position: absolute;
    z-index: 1000000;
    top: 0;
    right: 0;
    width: 80vw;
    height: 100%;
    overflow: auto;
    transform-origin: right center;
    padding: 20px;
    border-left: 1px solid #e0e0e0;
    padding-top: 25px;

    // new settings
    background-color: transparent;
    border: none;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    overflow: visible;
    padding: 0;
    transition: all 0.5s ease-in-out 0.05s;
    button.close-popup-button {
      background-color: #000;
    }

    body.status-bar & {
      top: 30px;
      height: calc(100vh - 30px);
    }

    .close-side-popup, .close-popup-button {
      position: absolute;
      top: 1%;
      right: 2%;
      z-index: 1000;
      &::after, &::before {
        background-color: white;
      }
    }
    .documentation-container {
      width: 100%;
      height: 100%;
      overflow: auto scroll;
      top: 0;
      right: 0;
      position: relative;
      display: block;
      width: 100vw;
      left: auto;
      margin: auto;
    }
    .swagger-container {
      right: 0;
      position: relative;
      margin: 0 auto;
      max-width: 1000px;

      right: 0;
      position: relative;
      margin: 0 0 0 auto;
      max-width: calc(100vw - 200px);
      background: white;
      width: 80%;
      min-height: 100vh;

      display: flex;
      flex-direction: column;
      .topbar .download-url-wrapper {
        margin-right: 3em;
      }
    }
  }

}


bs-datepicker-container, bs-daterangepicker-container {
  z-index: 1080000;
}

body {

  #products {
    color: white;
    background: linear-gradient(180deg, black, #151a23);

    background: linear-gradient(0deg,transparent 20%,black),linear-gradient(145deg,black, rgba(0,27,47,0.64));

    .page-inner {
      .boxes {
        background: none;
        ul {
          li {
            h2 {
              font-weight: normal;
              color: #d8e6f2;
              font-family: "NunitoSans";
            }
          }
        }
      }
      .trial-container {
        background: none;
        color: white;
        padding: 50px;
        h3 {
          font-size: 2em;
          color: #77a7ff;
          font-family: "NunitoSans-Light";
        }
      }
      .trial {
        background: none;
        color: white;
        &:before {
          display: none;
        }
        h4 {
          color: #77a7ff;
          font-family: "NunitoSans";
          font-family: "NunitoSans-Light";
        }
      }
    }
  }
  .rest-generator-footer {
    background: linear-gradient(0deg, #0d0d0d, #151a23);
    color: #ebebeb;
    .inner > div p {
      font-size: 1.5em;
      color: #d8e6f2;
      margin-bottom: 0;
      line-height: 1.2;
      text-shadow: 1px 1px 5px black;
    }
  }

  .container.footer-container {
    border-top: none;
  }
}


.flex-icon-text {
  display: flex;
  justify-content: start;
  align-self: center;
  & > .icon {
    width: 1.75em;
    text-align: center;
    margin-right: 0.5em;
    display: flex;
    align-self: center;
    justify-content: center;
  }
}

.clear-form-button {
  .icon {
      display: none;
  }
  span.text {
      display: inline-block;
  }
}
.narrow-side-menu {
  .clear-form-button {
      span.text {
          display: none;
      }
      .icon {
          display: block;
      }
  }
}

/* width */
::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  background: #262c36;
  background: #39404a;
    border: 1px solid black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #475774;
  border: 1px solid #1d3b67;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3d73d7;
}


// Checkbox
.input-container.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  input[type=checkbox].form-check-input {
    border-radius: 0;
    margin-right: 0.5em;
    width: 1.125em;
    height: 1.125em;
    border-width: 2px;
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
}




// NGX-BOOTSTRAP TABS

tabset.tab-container {

  .nav-tabs {
    display: flex;
    padding-top: 3em;
    margin-bottom: -2em;
    z-index: 2;
    li.nav-item {
      position: relative;
      margin-left: -1em;
      transition: margin-left 0.4s ease;
      &:first-of-type {
        margin-left: 0;
      }
      a.nav-link {
        padding: 1em 2em;
        border-color: transparent transparent #a29f9f transparent;
        border-width: 0 2em 2em 0;
        border-width: 0 2.45em 2.45em 0;
        width: auto;
        display: inline-block;
        border-style: solid;
        height: 0;
        padding: 0;
        margin-top: 0em;
        margin-bottom: 0em;
        position: absolute;
        position: relative;
        top: -2em;
        left: -1px;
        left: 0;
        background: none;
        z-index: 1;
        cursor: pointer;

        span {
          display: none;
        }
        span.bs-remove-tab {
          display: block;
          position: absolute;
          top: 10px;
          left: 10px;
          font-size: 0;
          width: 20px;
          height: 20px;
          transform: rotate(0deg);
          transition: transform .35s ease-out;
          &:after {
            display: block;
            font-size: 1rem;
            width: 16px;
            height: 1px;
            background: white;
            transform: rotate(45deg);
            content: "";
            position: absolute;
            top: 9px;
            left: 2px;
          }
          &:before {
            display: block;
            font-size: 1em;
            width: 16px;
            height: 1px;
            background: white;
            transform: rotate(-45deg);
            content: "";
            position: absolute;
            top: 9px;
            left: 2px;
          }
          &:hover {
            transform: rotate(180deg);
          }
        }

        .header-outer {
          position: relative;
          border-color: transparent transparent white transparent;
          border-width: 0 2.45em 2.45em 0;
          width: auto;
          border-style: solid;
          top: -4.7em;
          left: 0.75px;
          margin-right: -1.95em;
          top: -3.875em;
          margin-right: -2.375em;
          padding-right: 0.375em;
          content: "";
        }
        .header-inner {
          content: "";
          position: relative;
          top: 2.75em;
          left: 0.25em;
          border-color: transparent transparent #3d73d7 transparent;
          border-color: transparent transparent #475774 transparent;
          border-width: 0 2.45em 2.45em 0;
          width: auto;
          border-style: solid;
          margin-right: -2em;
          transition: border-color 0.8s ease;
          top: 2.25em;
          border-width: 0 2em 2em 0;
        }

        h2 {
          color: white;
          font-family: "NunitoSans-Light";
          font-size: 1.25em;
          margin: 0;
          padding: 0.25em 1em 0.3755em;
          top: 1.95em;
          position: relative;
          font-size: 1.125em;
          padding: 0.25em 0.5em 0.25em;
          top: 1.75em;
        }
        .header-outer.removable .header-inner h2 {
          padding-left: 2em;
        }
        &.active {
          z-index: 3;
          .header-inner {
            border-color: transparent transparent #3d73d7 transparent;
          }
        }
      }
    }
  }

  .tab-content {
    background: white;
    position: relative;
    z-index: 2;
    padding: 25px;
    border: 1px solid #a29f9f;
    box-shadow: 3px 6px 10px -5px #00000073;
  }
}
.tm-logo-long {
  position: relative;
  // &:after {
  //   content: "™";
  //   position: absolute;
  //   color: white;
  //   bottom: 0.25em;
  //   left: calc(50% + 5em);
  //   font-family: 'NunitoSans-ExtraLight';
  // }
  .narrow-side-menu & {
    &::after {
      left: auto;
      right: 0.25em;
      bottom: -0.75em;
    }
  }
}

.json-formatter-row .json-formatter-string, .json-formatter-row .json-formatter-stringifiable {
  white-space: break-spaces !important;
}
