// variables

// colors

$purple-primary: #6140b4;
$purple-hover-light: #8a61ef;
$purple-active-dark: #51388e;


$color-primary-blue: #3f82ff;
$color-secondary-blue: #77a7ff;
$color-blue-dark-bg: #80b3ff;

$color-secondary-blue-transparent: rgba(119,167,255,0.05);

$color-disabled-gray: #a8a6ae;
$color-disabled-gray: #9eb8e7;
$color-disabled-gray-hover: #797682;
$color-disabled-gray-transparent: rgba(168,166,174,0.05);
$color-gray-dark: #787878;

$color-error-red: #d30707;
$color-warning-orange: #e8b005;

$color-valid-green: #28a745;


//  app-view

$appViewHeaderHeight: 2.5em;

@function sidebar_width() {
    @return 200px;
}

@media only screen and (min-width: 1000px) {
    @function sidebar_width() {
        @return 350px;
    }
}
