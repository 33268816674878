//  Modal styles
@keyframes fadeInFromNone1 {
    0% {
        display: none;
        opacity: 0;
        transform: translate(0,-50px);
        // transform: scale(0.5);
        // transform: translate(0,-30%)scale(1)rotate3d(1, 0, 0, 45deg);
    }

    1% {
        display: block;
        opacity: 0;
        transform: translate(0,-50px);
        // transform: scale(0.5);
        // transform: translate(0,-30%)scale(1)rotate3d(1, 0, 0, 45deg);
    }
    20% {
        // transform: translate(0,-50%);
        // transform: translate(0,-30%)scale(1)rotate3d(1, 0, 0, 45deg);
    }

    60% {
        display: block;
        // opacity: 1;
        // transform: translate(0,-50px);
    }

    100% {
        display: block;
        opacity: 1;
        transform: translate(0,0);
        // transform: scale(1);
    }
}
.modal-backdrop {
    background: radial-gradient(rgb(17 22 37 / 55%), rgb(0 0 0 / 85%));
}
.modal-backdrop.show {
    z-index: 1000000;
    opacity: 1;
    transition: opacity 0.3s linear;

    // background: radial-gradient(rgba(150,156,173,0.26), rgb(53 55 62 / 81%));
    // background: radial-gradient(rgb(17 22 37 / 70%), rgb(0 0 0 / 90%));
    // opacity: 1;
    // &:before {
	// 	content: "";
	// 	top: 0;
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 50%;
	// 	background: linear-gradient(180deg,#0000008c, transparent);
	// }
	// &:after {
	// 	content: "";
	// 	bottom: 0;
	// 	position: fixed;
	// 	width: 100%;
	// 	height: 50%;
	// 	background: linear-gradient(0deg,#0000008c, transparent);
	// }

}
.modal-backdrop.fade {
    // transition: opacity 1s ease;
}
.modal-dialog {
    margin: auto;
    top: calc(50% - 100px);
    width: 90%;
    max-width: 37.5em;
    top: auto;
    perspective: 37.5em;
    transform: none !important;
    // transform: translate(0,-50px);
    &.modal-lg {
        max-width: 62.5em;
    }
    // &:before {
    //     content: "";
    //     display: block;
    //     top: 0;
    //     left: 0;
    //     position: absolute;
    //     z-index: 2;
    //     height: 5px;
    //     width: 100%;
    //     background: linear-gradient(90deg, #3f82ff, #77a7ff);
    // }
}
.modal.show .modal-dialog .modal-content {
    animation: fadeInFromNone1 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s;
    animation: fadeInFromNone1 0.5s cubic-bezier(0.43, 1.19, 0.51, 1.04) 0.25s;
    animation-fill-mode: forwards;
    transition: none !important;
    // transform: translate(0,-50px);
    opacity: 0;
    &:before {
        content: "";
        display: block;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 2;
        height: 5px;
        width: 100%;
        background: linear-gradient(90deg, #3f82ff, #77a7ff);
    }
}
.modal-open .modal {
    z-index: 1000000;
    display: flex !important;
    justify-content: center;
    align-items: center;
    transition: none !important;
}
.modal-content {
    border: none;
    border-radius: 0;
    padding: .5em;
    box-shadow: 2px 2px 10px 0 rgb(0 0 0 / 50%);

    p.field-display-value {
      display: flex;
      align-items: baseline;
      .label {
        flex-shrink: 1;
        font-family: "NunitoSans-ExtraLight";
        // margin-right: 2.5em;
        font-weight: normal;
        font-size: 0.875em;
        text-align: left;
        text-transform: uppercase;
        padding-right: 1em;
        width: -moz-fit-content;
        width: fit-content;
        text-wrap: nowrap;
      }
      .value {
        font-family: "NunitoSans-Light";
        font-size: 1em;
        padding-left: 0.5em;
        border-bottom: 1px solid #aebbda24;
        text-align: left;
        color: black;
        overflow: visible;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        a.copy-to-clipboard {
          margin-top: 0;
        }
      }
      &.smaller {
        font-size: 0.875em;
      }
    }


    button.close {
        z-index: 1;
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        font-size: 1.52em;
        transition: all .25s ease;
        padding: .25em;
        color: #3d73d7;
        min-width: 0;
        font-family: 'NunitoSans-Black';
        font-size: 2em;
        opacity: 1;
    }
    .border {
        border: 1px solid #f7f7f7 !important;
        padding: 0.5em 0.75em;
        margin-bottom: 1em;
    }
    .border-with-title {
        border: 0.5px solid #d3d3d39e !important;
        padding: 0.5em 0.75em;
        margin-bottom: 2em;
        border-radius: 5px;
        margin-top: 2em;
        &.no-bottom-margin {
            margin-bottom: 0;
        }
        & > h4 {
            color: #69626f;
            background: white;
            font-size: 1.0625em;
            font-family: NunitoSans-Light;
            top: -1.25em;
            left: -0.375em;
            display: inline-block;
            padding: 0 0.25em;
            position: relative;
            color: #2862cf;
        }
        & > div:first-of-type {
            margin-top: -2em;
        }
        & > p:first-of-type {
            margin-top: -1em;
        }
    }
    .border-with-no-title {
      border: 0.5px solid #d3d3d39e !important;
      padding: 0.5em 0.75em;
      margin-bottom: 2em;
      border-radius: 5px;
      margin-top: 2em;
      &.smaller {
        font-size: 0.875em;
      }
    }
    h2 {
        color: black;
        font-family: 'NunitoSans-LightItalic';
        font-size: 1.25em;
        padding: 0.5em;
        margin-bottom: 1em;
        font-weight: normal;
        line-height: 1.5;
        .blue-highlight {
            color: #3f82ff;
            font-family: 'NunitoSans-Italic';
        }
    }
    h3 {
        color: #3f82ff;
        font-family: 'NunitoSans-ExtraLight';
        font-weight: normal;
        font-size: 1.25em;
    }
    .label {
        font-family: 'calibri';
        margin-right: 0.5em;
        font-weight: normal;
        font-size: 1.125em;
        &:after {
            content: ":"
        }
    }
    .value {
        font-family: 'NunitoSans-SemiBold';
        font-size: 1.125em;
        &.ellipsis {
            text-overflow: ellipsis;
            padding-right: 2em;
            overflow: hidden;
            white-space: nowrap;
        }
        .ellipsis {
          text-overflow: ellipsis;
          padding-right: 0.5em;
          overflow: hidden;
          white-space: nowrap;
          max-width: calc(100% - 1em);
          // margin-left: -2em;
          // font-size: 1em;
        }
        a.copy-to-clipboard {
          font-size: 1.375em;
          color: #777f88;
          transition: opacity 0.25s ease;
          margin-top: -0.25em;
          cursor: pointer;
        }
    }
    .modal-buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        & > button {
            margin: .5em;
            font-size: 1em;
            &:only-child {
                margin: 8px auto;
            }
        }
    }
    h5 {
        color: #333333;
        // color: $color-gray-dark;
        text-align: center;
        font-family: 'NunitoSans-ExtraLight';
        font-weight: normal;
        font-size: 1.25em;
        margin-bottom: 1em;
    }

    .blue-highlight {
        color: $color-primary-blue;
        font-family: 'NunitoSans-Italic';
    }
    .red-highlight {
        color: $color-error-red;
        font-family: 'NunitoSans-Italic';
    }

    .warning {
        &:before {
            content: "\f071";
            color: $color-warning-orange;
            margin-right: 0.5em;
            font-family: fontawesome;
        }
    }
    .no-valid {
        &:before {
            content: "\f057";
            color: $color-error-red;
            margin-right: 0.5em;
            font-family: fontawesome;
        }
    }

    .valid {
        &:before {
            content: "\f058";
            color: $color-valid-green;
            margin-right: 0.5em;
            font-family: fontawesome;
        }
    }

    .highlight {
        font-family: 'NunitoSans-SemiBold';
        color: black;
    }

    p {
        color: #333333;
        font-family: 'NunitoSans-Light';

        &.centered {
            text-align: center;
        }

        &.line-with-right-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            .value {
                flex-grow: 1;
                text-overflow: ellipsis;
                padding-right: 2em;
            }
        }
        .highlight {
            font-family: 'NunitoSans-SemiBold';
            color: black;
        }
        .blue-highlight {
            color: $color-primary-blue;
            font-family: 'NunitoSans-Italic';
        }
        .red-highlight {
            color: $color-error-red;
            font-family: 'NunitoSans-Italic';
        }

    }
}
.modal-header {
  padding: 0 15px 15px 0px;
  border-bottom: none;
  margin: 0 15px;
//   border-bottom: 1px solid rgba(229,229,229,0.5);
  min-height: 2em;
  .modal-title {
    color: #3f82ff;
    line-height: 1.5;
    margin: 0.375em 0.5em 0;
    width: 100%;
    text-align: center;

    color: #2862cf;
    line-height: 1.5;
    margin: 0.75em 0em 0;
    text-transform: uppercase;
    font-family: "NunitoSans-ExtraLight";
    border-bottom: 1px solid rgb(191 191 191 / 25%);
    font-size: 1.5em;
  }
}
.modal {
    &:before {
        // content: "";
		top: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		// background: linear-gradient(180deg,#0000008c, transparent);
        // background: radial-gradient(rgba(17, 22, 37, 0.7), rgba(0, 0, 0, 0.9));
        background: radial-gradient(rgb(17 22 37 / 51%), rgb(0 0 0 / 74%));
        background: rgba(73, 73, 73, 0.425);
    }
}
.modal-body {
    // border-top: 1px solid rgba(229,229,229,0.5);
}

.modal-open {
    overflow: hidden;
    // overflow: auto;
    .wrapper.app-content, goldninefooter {
        // filter: blur(5px);
        // transition: filter .35s ease;
        //  ADD THIS BACK WHEN MODALS ARE INSIDE OWN COMPONENT
    }
    // &:before {
	// 	content: "";
	// 	top: 0;
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 50%;
	// 	background: linear-gradient(180deg,#0000008c, transparent);
	// }
	// &:after {
	// 	content: "";
	// 	bottom: 0;
	// 	position: fixed;
	// 	width: 100%;
	// 	height: 50%;
	// 	background: linear-gradient(0deg,#0000008c, transparent);
	// }
}

#pop, #forgotPasswordForm {
    margin: 0px auto;
    .modal-dialog {
        margin: 94px auto;
        position: absolute;
        right: 50%;
        margin-right: -421px;
        width: 400px;
        @media screen and (max-width: 1200px) {
            margin-right: 0;
            right: 166px;
        }
    }
}

.modal-footer {
    border-top: 1px solid rgba(229,229,229,0.5);
    clear: both;
    button {
        margin-left: 1em;
    }
}

.datePickerIcon {
    display: inline-block;
    & > input {
        // display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        padding: 0;
        border: none;
    }
}

.bs-datepicker {
    opacity: 0;
    animation: fadeInFromNone1 0.5s cubic-bezier(0.43, 1.19, 0.51, 1.04) 0.25s;
    animation-fill-mode: forwards;
}

.centered-modal-table {
    margin-left: auto;
    margin-right: auto;
    padding: 0.5em 0.75em;
    margin-bottom: 0em;
    & > p:first-of-type {
        margin-bottom: 0;
        padding-left: 0.4375em;
    }
    ul, table {
        display: table;
        margin: 0 auto;
        width: 100%;
        padding: 0;
        li, tr {
            display: table-row;
            list-style: none;
            width: 90%;
            margin: 0 auto 1em;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 1em;
            .preposition {
                margin: 0 1em;
                color: #444444;
                font-family: 'NunitoSans-ExtraLightItalic';
                padding-top: 0;
                padding-bottom: 0;
            }
            & > span, td {
                display: table-cell;
                width: auto;
                padding: 0 0.5em;
                padding-bottom: .5em;
                padding-top: 1.5em;
                letter-spacing: .5px;
                &.label {
                    font-family: 'NunitoSans-ExtraLight';
                    margin-right: 2.5em;
                    font-weight: normal;
                    font-size: .875em;
                    text-align: left;
                    text-transform: uppercase;
                    padding-right: 5em;
                    width: fit-content;
                    text-wrap: nowrap;
                }
                &.value {
                    font-family: 'NunitoSans-Light';
                    font-size: 1em;
                    padding-left: .5em;
                    border-bottom: 1px solid #aebbda24;
                    text-align: left;
                    color: black;

                    overflow: visible;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    &.not-applicable {
                        color: #a4a4a4;
                        font-family: "NunitoSans-Light";
                        font-size: 0.875em;
                    }

                    & > a {
                        margin-left: 0.5em;
                        line-height: 1;
                        display: inline-block;
                        color: #777f88;
                        transition: opacity .25s ease;
                        cursor: pointer;
                        &.mailto-icon {
                            font-size: 1.375em;
                        }
                        &.call-icon {
                            font-size: 1.5em;
                        }
                        &:hover, &:focus {
                            opacity: .75;
                        }
                    }
                }
            }
        }
    }
    &.centered-value {
        ul li .value, table tr .value  {
            justify-content: center;
        }
    }
    &.full-width {
        padding: .5em 0;
        ul, table {
            li, tr {
                width: 100%;
            }
        }
    }
}

.text-with-toggle {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    align-items: center;
    & > span {
        line-height: 1;
        order: 1;
    }
    &:after {
        display: block;
        height: 1px;
        width: auto;
        background-color: white;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100' fill='none' stroke='%23DDDDDDFF' stroke-width='1' stroke-dasharray='11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        margin: 0 1em;
        flex-grow: 1;
        order: 2;
        content: "";
    }
    & > lib-toggle-switch, & > lib-form-field {
        order: 3;
    }
}
