$diameter: 4em;
$radius: $diameter / 2;
$border-color: #c2c7cc;
$border-color: rgb(255 255 255 / 80%);
$border-color-hover: #f26a3d;
$border-color-hover: #3d9df2;
$colse-color: #8a9199;
$colse-color: rgb(255 255 255 / 80%);

@keyframes loadingButtonSpinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

@keyframes showCheck {
	0% {
		opacity: 0;
		transform: scale(.95);
	}
	1% {
		opacity: 0;
		transform: scale(.95);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

button, a[role="button"],
.upload-file + label {
	&.primary {
		display: inline-block;
		transition: all .15s ease-in-out;
		border: none;
		border-radius: 0;
		background-color: $color-primary-blue;
		padding: 3px 20px;
		text-align: center;
		text-transform: uppercase;
		color: #fff;
		font-family: "NunitoSans-Light";
		font-size: 1.125em;
		padding: 0.15em 1em;
		&:hover, &:focus {
			outline: none;
			background-color: $color-secondary-blue;
			text-decoration: none;
		}
		&:disabled:not(.loading):not(.no-background) {
			background: $color-disabled-gray;
			cursor: not-allowed;
			filter: alpha(opacity=65);
			&:hover, &:focus {
				background-color: $color-disabled-gray-hover;
			}
		}
	}
	&.danger {
		display: inline-block;
		transition: all 0.3s ease 0s;
		border: none;
		border-radius: 0;
		box-shadow: 0 0 2px -2px rgba(255,73,73,0.4);
		background-color: #ff4949;
		background-color: $color-error-red;
		padding: 5px 25px 5px 25px;
		text-align: center;
		text-transform: uppercase;
		color: #fff;
		font-family: 'NunitoSans';
		&:hover, &:focus {
			background-color: rgba(255,73,73,0.65);
			text-decoration: none;
			color: #fff;
		}
		&:disabled:not(.loading):not(.no-background) {
			background-color: $color-disabled-gray;
			cursor: not-allowed;
			filter: alpha(opacity=65);
			&:hover, &:focus {
				background-color: $color-disabled-gray-hover;
			}
		}
	}

	&.trash-can > fa-icon {
		font-size: 0.8125em;
	}
	&.green {
		// background-color: #27a027;
		&:hover, &:focus {
			background-color: #5cb75d;
		}
	}
	&.red {
		// background-color: #27a027;
		&:hover, &:focus {
			// background-color: #5cb75d;
			background-color: #ff4949;
			background-color: rgba(255,73,73,0.65);
		}
	}
  &.smaller-button {
    font-size: 1em;
    padding: 0.125em 0.75em;
  }
	&.secondary {
		display: inline-block;
		transition: all 0.15s ease 0s;
		border: 2px solid $color-primary-blue;
		border-radius: 0;
		background-color: white;
		text-align: center;
		text-transform: uppercase;
		color: $color-primary-blue;
		font-family: 'NunitoSans-SemiBold';
		// padding: 1px 21px 1px 21px;
		// increased to matcha height of primary button
        padding: 3px 21px 2px 21px;
		padding: .15em 1.15em 0.1em 1.15em;
		.site-header &, &.dark-container {
			border: 2px solid #80b3ff;
			color: #80b3ff;
		}
		&.no-bg {
			background: transparent;
		}
		&:hover, &:focus {
			border-color: $color-secondary-blue;
			background-color: $color-secondary-blue-transparent;
			text-decoration: none;
			color: $color-secondary-blue;
		}
		&:disabled:not(.loading),
        &.disabled:not(.loading) {
			border-color: $color-disabled-gray;
			background-color: $color-disabled-gray-transparent;
			cursor: not-allowed;
			color: $color-disabled-gray;
			&:hover, &:focus {
				border-color: $color-disabled-gray-hover;
				color: $color-disabled-gray-hover;
			}
		}
    &.lighter {
      border: 2px solid #80b3ff;
      color: #80b3ff;
    }
    &.smaller-button {
      font-size: 1em;
      padding: 0 0.625em;
    }
	}
	&.text-button {
		box-sizing: border-box;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: none;
		border-radius: 0.25em;
		background: transparent;
		padding: 0 0.5em;
		min-width: 4em;
		height: 2.25em;
		text-decoration: none;
		color: #025aff;
		font-family: "NunitoSans";
    &.lighter {
      color: #80b3ff;
    }
		&.auto-height {
			height: auto;
		}
		.site-header & {
			color: #80b3ff;
			font-size: 1.125em;
			&:hover {
				background: transparent;
			}
			@media screen and (max-width: 1000px) {
				font-size: 1.125em;
			}
		}

		&.dark-bg {
			color: #80b3ff;
		}
		&.bigger {
			font-size: 1.125em;
		}
		&:hover, &:focus {
			background-color: rgba(0, 171, 238, 0.07);
			color: $color-secondary-blue;
		}
		&:disabled {
			color: $color-disabled-gray;
			&:hover, &:focus {
				background-color: rgba(218, 218, 218, 0.07);
				color: $color-disabled-gray-hover;
				cursor: auto;
			}
		}
		&.text-button-sm {
			height: 30px;
			font-size: 0.85em;
		}
		&.text-button-no-bg {
			&:hover, &:focus {
				background-color: transparent;
				color: $purple-hover-light;
			}
		}
		&.above-text-input {
			position: relative;
			float: right;
			z-index: 1000;
			margin-top: 0;
			margin-right: -.25em;
			border-radius: 2px;
			padding: 4px 8px 2px;
			height: auto;
			text-align: right;
			text-transform: capitalize;
			font-family: "NunitoSans-SemiBold";
			font-size: 14px;
		}
	}

  &.vertical-button {
    padding: 1em 0.25em;
  }

  &.icon-with-text {
    & > fa-icon {
      margin-left: -0.25em;
      margin-right: 0.5em;
    }
    &.icon-on-right > fa-icon {
      margin-right: -0.25em;
      margin-left: 0.5em;
    }
  }
	&.collapse-button {
		&:after {
			display: inline-block;
			transition: all 0.25s ease;
			margin-bottom: 0.15em;
			margin-left: .5em;
			text-rendering: auto;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			content: "\f078";
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
		&[aria-expanded="true"]:after {
			transform: rotate(180deg);
		}
	}
	&.full-width {
		width: 100%;
	}
	&.icon-button {
		border-radius: 100%;
		padding: 0;
		width: 2em;
		min-width: 0;
		height: 2em;
		line-height: 2.125em;
		&.copy-to-clipboard fa-icon {
			transform: scaleX(-1)scaleY(1.125)translate(1px, 0px);
    		display: block;
		}
		&.icon-no-background {
			color: #3f82ff;
			background: none;
			font-size: 1.5em;
			transition: color .35s ease;
			&:hover {
				color: #77a7ff;
				background: none;
			}
		}
	}
	&.loading {
		position: relative;
		cursor: wait;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 111;
			background-color: rgba(255, 255, 255, 0.68);
			width: 100%;
			height: 100%;
			content: "";
		}
		&:after {
			position: absolute;
			top: 48%;
			left: 52%;
			transform: translate3d(-50%, -50%, 0);
			transform-origin: 45% 53.5%;
			z-index: 1111;
			margin: 0;
			background-image: url(/assets/images/circleA.svg);
			background-repeat: no-repeat;
			background-size: contain;
			width: 1.5em;
			height: 1.5em;
			animation: 2s linear infinite loadingButtonSpinner;
			content: "";
		}
		&.icon-button::before {
			border-radius: 50%;
		}
    &.smaller-button::after {
      // top: 5%;
      // left: calc(50% - .75em);
    }
	}
	&.collapsible-button {
		fa-icon {
			display: none;
		}
		span.text {
			display: inline-block;
		}
		&.collapsed {
			padding: 5px 0 0px;
			width: 35px;
			text-align: center;
			fa-icon {
				display: inline-block;
				line-height: 1;
				font-size: 1.375em;
			}
			span.text {
				display: none;
			}
		}
	}
  &.external-link {
    &:after {
			content: "\f35d";
			font-family: FontAwesome;
			margin-left: 0.75em;
			margin-right: -0.25em;
		}
  }
	&.arrow-link {
		&:after {
			content: "\f054";
			font-family: FontAwesome;
			margin-left: 0.75em;
			margin-right: -0.25em;
		}
	}

	&.add-button {
		font-size: 0.875em;
		padding: 0;
		line-height: 1;
		border-radius: 50%;
		width: 2em;
		height: 2em;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&.close-button {
		position: absolute;
		// top: calc(50% - #{$diameter});
		// right: calc(50% - #{$radius});
		box-sizing: border-box;

		width: calc(#{$diameter} - 0.5em);
		height: calc(#{$diameter} - 0.5em);
  		border-radius: calc(#{$diameter} - 0.5em);

		border: 2px solid $border-color;
		border: 2px solid rgb(255 255 255 / 32%);
		color: transparent;
		cursor: pointer;
		transition: all 0.3s ease, opacity 1s ease 1s;
		background: rgb(72 155 226 / 20%);

		font-size: 14px;

  		&:before, &:after {
			position: absolute;
			width: $radius;
			height: 2px;
			left: 50%;
			top: 100%;
			margin-left: calc(-#{$radius} / 2);
			margin-top: calc(-1.75em / 1);

			content: '';
			background: $colse-color;
			transition: all ease 1s;
		}

		&:before {
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}

		&:after {
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		&:hover, &:focus {
			-webkit-transform: rotate(180deg);
			transform: rotate(180deg);
			border-color: $border-color-hover;
			border-color: #3d9df282;
			// background: fade($border-color-hover, 10%);
			background: rgba($border-color-hover, .25);

    		&:before, &:after {
      			background: $color-primary-blue;
				background: #8db5ff;
    		}
  		}
		  &.modal-close {
			right: 0.5em;
			top: 0.75em;
			font-size: 0.75em;
			// background: rgb(72 126 226 / 11%);
			width: calc(4em - 1em);
			height: calc(4em - 1em);
			// border: 2px solid rgb(0 60 111 / 16%);
			background: none;
			border: none;
			z-index: 2;
			&:before, &:after {
				top: 50%;
				margin-top: -1px;
				margin-left: -1em;
				// margin-top: calc(-1.45em / 1);
				background: rgb(28 88 150 / 80%);
				transition: opacity .3s ease, transform 1s ease;
			}
			&:hover, &:focus {
				background: rgb(61 157 242 / 6%);
				&:before, &:after {
					background: #8db5ff;
				}
			}
		  }
	}
	&.accordionButton {
		display: block;
		border: none;
		background: none;
		font-size: 0.75em;
		width: calc(6em - 1em);
		height: calc(6em - 1em);
		top: 0em;
		right: 0em;
		color: rgba(28, 88, 150, 0.8);
		// position: absolute;
		position: relative;

		box-sizing: border-box;

		width: calc(#{$diameter} - 1.5em);
		height: calc(#{$diameter} - 1.5em);
		border-radius: calc(#{$diameter} - 0.5em);

		border: 2px solid $border-color;
		border: 2px solid rgb(255 255 255 / 32%);
		color: transparent;
		cursor: pointer;
		transition: all 0.3s ease, opacity 1s ease 1s;
		background: rgb(72 155 226 / 20%);

		font-size: 0.75em;
		// background: rgb(72 126 226 / 11%);
		width: calc(4em - 1em);
		height: calc(4em - 1em);
		// border: 2px solid rgb(0 60 111 / 16%);
		background: none;
		border: none;
		z-index: 2;
		&:before, &:after {
			position: absolute;
			width: calc(#{$radius} / 2);
			height: 0.125em;
			left: 60%;
			top: 100%;
			margin-left: calc(-#{$radius} / 2);
			margin-top: calc(-1.75em / 1);

			content: '';
			background: $colse-color;
			transition: all ease 1s;

			top: 50%;
			margin-top: -0.0625em;
			margin-left: -1em;
			// margin-top: calc(-1.45em / 1);
			background: rgb(28 88 150 / 80%);
			transition: opacity .3s ease, transform .5s ease-in-out, left .5s ease-in-out, background-color .25s ease;
		}

		&:before {
			// -webkit-transform: rotate(-45deg);
			transform: rotate(-45deg)translate(calc(#{$radius} / 12), calc(#{$radius} / 3));
			transform: rotate(-45deg)translate(0, calc(#{$radius} / 4));
		}


		&:after {
			// -webkit-transform: rotate(45deg);
			transform: rotate(45deg)translate(calc(#{$radius} / 12), calc(-#{$radius} / 3));
			transform: rotate(45deg)translate(0, calc(-1 * #{$radius} / 4));
		}

		&:hover, &:focus {
			// -webkit-transform: rotate(180deg);
			transform: rotate(0deg)scale(1.1);
			border-color: $border-color-hover;
			border-color: #3d9df282;
			// background: fade($border-color-hover, 10%);
			// background: rgba($border-color-hover, .25);

			&:before, &:after {
				//   background: $color-primary-blue;
				background: #8db5ff;
			}
		  }
		&.accordion-open {
			transform: rotate(-90deg);
			&:after {
				left: 50%;
				transform: rotate(-45deg)translate(0.5em, 0.0625em);
				transform: rotate(-45deg)translate(calc($radius / 4), 0);
			}
			&:before {
				left: 50%;
				transform: rotate(45deg)translate(0.5em, -0.0625em);
				transform: rotate(45deg)translate(calc($radius / 4), 0);
			}
			// &:hover, &:focus {
			//     transform: rotate(0deg);
			//     &:before {
			//         transform: rotate(-45deg)translate(2px, 8px);
			//     }

			//     &:after {
			//         transform: rotate(45deg)translate(2px, -8px);
			//     }
			// }
		}


	}

	&.accordion-collapse-all {
		display: block;
		border: none;
		background: none;
		font-size: 0.75em;
		width: calc(6em - 1em);
		height: calc(6em - 1em);
		top: 0em;
		right: 0em;
		color: rgba(28, 88, 150, 0.8);
		// position: absolute;
		position: relative;

		box-sizing: border-box;

		width: calc(#{$diameter} - 1.5em);
		height: calc(#{$diameter} - 1.5em);
		border-radius: calc(#{$diameter} - 0.5em);

		border: 2px solid $border-color;
		border: 2px solid rgb(255 255 255 / 32%);
		color: transparent;
		cursor: pointer;
		transition: all 0.3s ease, opacity 1s ease 1s;
		background: rgb(72 155 226 / 20%);

		font-size: 0.75em;
		// background: rgb(72 126 226 / 11%);
		width: calc(4em - 1em);
		height: calc(4em - 1em);
		// border: 2px solid rgb(0 60 111 / 16%);
		background: none;
		border: none;
		z-index: 2;
		width: 2em;

		.button-inner {
			transform: rotate(-90deg)translate(4px,0);
			& > span {
				display: block;
				position: absolute;
				width: 7px;
				height: 7px;
				border-top: 2px solid #3d73d7;
				border-right: 2px solid #3d73d7;
				transform: rotate(45deg)translate(1px, -6px);

				width: 12px;
				height: 12px;
				transform: rotate(45deg) translate(-11px, 3px);
			}
			&:before, &:after {
				position: absolute;
				width: calc(#{$radius} / 2);
				height: 2px;
				left: 60%;
				top: 100%;
				margin-left: calc(-#{$radius} / 2);
				margin-top: calc(-1.75em / 1);

				content: '';
				background: $colse-color;
				transition: all ease 1s;

				top: 50%;
				margin-top: -1px;
				margin-left: -1em;
				// margin-top: calc(-1.45em / 1);
				background: rgb(28 88 150 / 80%);
				transition: opacity .3s ease, transform .5s ease-in-out, left .5s ease-in-out, background-color .25s ease;
			}
			&:before {
				// -webkit-transform: rotate(-45deg);
				transform: rotate(-45deg)translate(calc(#{$radius} / 12), calc(#{$radius} / 3));
				transform: rotate(-45deg)translate(0, calc(#{$radius} / 4));
			}


			&:after {
				// -webkit-transform: rotate(45deg);
				transform: rotate(45deg)translate(calc(#{$radius} / 12), calc(-#{$radius} / 3));
				transform: rotate(45deg)translate(0, calc(-1 * #{$radius} / 4));
			}
		}

	}
	&.accordion-expand-all {
		display: block;
		border: none;
		background: none;
		font-size: 0.75em;
		width: calc(6em - 1em);
		height: calc(6em - 1em);
		top: 0em;
		right: 0em;
		color: rgba(28, 88, 150, 0.8);
		// position: absolute;
		position: relative;

		box-sizing: border-box;

		width: calc(#{$diameter} - 1.5em);
		height: calc(#{$diameter} - 1.5em);
		border-radius: calc(#{$diameter} - 0.5em);

		border: 2px solid $border-color;
		border: 2px solid rgb(255 255 255 / 32%);
		color: transparent;
		cursor: pointer;
		transition: all 0.3s ease, opacity 1s ease 1s;
		background: rgb(72 155 226 / 20%);

		font-size: 0.75em;
		// background: rgb(72 126 226 / 11%);
		width: calc(4em - 1em);
		height: calc(4em - 1em);
		// border: 2px solid rgb(0 60 111 / 16%);
		background: none;
		border: none;
		z-index: 2;

		width: 2em;
		.button-inner {
			transform: rotate(90deg)translate(4px,0);
			& > span {
				display: block;
				position: absolute;
				width: 7px;
				height: 7px;
				border-top: 2px solid #3d73d7;
				border-right: 2px solid #3d73d7;
				transform: rotate(45deg)translate(1px, -6px);

				width: 12px;
				height: 12px;
				transform: rotate(45deg) translate(-11px, 3px);
			}
			&:before, &:after {
				position: absolute;
				width: calc(#{$radius} / 2);
				height: 2px;
				left: 60%;
				top: 100%;
				margin-left: calc(-#{$radius} / 2);
				margin-top: calc(-1.75em / 1);

				content: '';
				background: $colse-color;
				transition: all ease 1s;

				top: 50%;
				margin-top: -1px;
				margin-left: -1em;
				// margin-top: calc(-1.45em / 1);
				background: rgb(28 88 150 / 80%);
				transition: opacity .3s ease, transform .5s ease-in-out, left .5s ease-in-out, background-color .25s ease;
			}
			&:before {
				// -webkit-transform: rotate(-45deg);
				transform: rotate(-45deg)translate(calc(#{$radius} / 12), calc(#{$radius} / 3));
				transform: rotate(-45deg)translate(0, calc(#{$radius} / 4));
			}


			&:after {
				// -webkit-transform: rotate(45deg);
				transform: rotate(45deg)translate(calc(#{$radius} / 12), calc(-#{$radius} / 3));
				transform: rotate(45deg)translate(0, calc(-1 * #{$radius} / 4));
			}
		}

	}
	&.request-and-download {
		position: relative;
		padding-right: 1.15em;
		transition: padding-right 0.25s ease;
		&:after {
			position: absolute;
			font-family: fontawesome;
			opacity: 0;
			display: block;
			right: .5em;
			top: 0;
			content: "\f00c",
		}
		&.completed {
			padding-right: 2.15em;
			&::after {
				animation: showCheck 0.5s ease 0.3s;
				animation-fill-mode: forwards;
			}
		}
	}
}
.upload-file {
	position: absolute;
	opacity: 0;
	z-index: -1;
	width: 0.1px;
	height: 0.1px;
	overflow: hidden;
}
.upload-file + label {
	cursor: pointer;
	padding-left: 2em;
	font-weight: normal;
	&:before {
		display: inline-block;
		margin-right: 0.5em;
		font-family: "FontAwesome";
		content: "\f0ee";
	}
}
.checkbox-button {
	position: relative;
	cursor: pointer;
	input[type=checkbox] {
		display: inline-block;
		position: absolute;
		top: 50%;
		right: 0;
		bottom: 0;
		left: 1em;
		transition: all 0.3s ease-out 0s;
		z-index: 1000;
		margin-top: -10px;
		margin-right: 0.5em;
		outline: none;
		border: 2px solid #cccccc;
		border-radius: 0;
		box-shadow: 0 0 2px -2px rgba(0, 0, 0, 0.4);
		background: #cbd1d8;
		background-color: #fff;
		cursor: pointer;
		width: 20px;
		height: 20px;
		color: #fff;
		font-family: 'sansation-light';
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		appearance: none;
		//border: 2px solid $purple-primary;
		&:before {
			display: inline-block;
			position: absolute;
			top: 0px;
			left: -1px;
			width: 20px;
			height: 20px;
			text-align: center;
			font: normal normal normal 16px/1 FontAwesome;
			font-weight: 500;
			content: '✔';
		}
	}
	span {
		display: inline-block;
		transition: all 0.3s ease 0s;
		border: 2px solid $purple-primary;
		border-radius: 0;
		box-shadow: 0 0 2px -2px rgba(0, 0, 0, 0.4);
		background-color: #fff;
		padding: 3px 23px 3px 23px;
		padding-left: 3em !important;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
		color: $purple-primary;
		font-family: 'NunitoSans';
		&:hover, &:focus {
			border-color: $purple-hover-light;
			background-color: rgba(97, 64, 180, 0.05);
			text-decoration: none;
			color: $purple-hover-light;
		}
		&:active {
			//   border-color: $purple-active-dark;
			//   color: $purple-active-dark;
			//   box-shadow: 0 0 8px -1px rgba(0,0,0,0.4);
		}
	}
	&:hover {
		input[type=checkbox] {
			&::before {
				color: rgba(97, 64, 180, 0.25);
			}
		}
		input[type=checkbox]:checked {
			background-color: $purple-hover-light;
			&:before {
				opacity: .7;
				color: white;
			}
			& ~ span {
				background-color: $purple-hover-light;
			}
		}
		span {
			border-color: $purple-hover-light;
			background-color: rgba(97, 64, 180, 0.05);
			text-decoration: none;
			color: $purple-hover-light;
		}
	}
	input[type=checkbox]:checked {
		border: none;
		background-color: $purple-primary;
		color: white;
		&:before {
			top: -3px;
			font-size: 24px;
		}
		& ~ span {
			box-shadow: 0 0 2px -2px rgba(0, 0, 0, 0.4);
			background-color: $purple-primary;
			color: white;
		}
		&:hover {
			opacity: .7;
		}
	}
}


// Bootstrap radio buttons group
.btn-group {
	.btn-success {
		background-color: #adb5bd;
		background-color: #9eb8e7;
		font-family: "NunitoSans-Light";
		text-transform: uppercase;
		// border-color: #797682;
		border: none;
		box-shadow: none;
		// border-radius: 0;
		margin: 0;
		&:focus {
			box-shadow: 0 0 0 0.125em rgb(63 130 255 / 50%);
		}
		&.active {
			background-color: #3f82ff;
			// border-color: #3f82ff;
		}
	}
	&.centered {
		width: 100%;
		justify-content: center;
		.btn-success {
			flex: 0 0 auto;
		}
	}
  &.smaller-button {
    .btn-success {
      padding: 0em 0.75rem;
    }
  }
}
