.bs-datepicker-body {
    table {
        td {
            span {
                font-size: 1em;
                color: #78a7ff;
                font-weight: 400;
            }
        }
        td.disabled span,
        td span.disabled {
            color: #adb5bd;
            font-weight: 100;
        }
    }
}

.bs-datepicker-head {

}

.theme-dark-blue .bs-datepicker-head {
    background-color: #3f82ff;
}

.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
    font-size: 1.875em;
    line-height: 0.75em;
}

.theme-dark-blue .bs-datepicker-body table td span.selected {
    background-color: #3f82ff;
}

.theme-dark-blue .bs-datepicker-body table td.week span {
    color: #3f82ff;
}