// This is for page layout

.wrapper.app-content {
	flex: 1 0 auto;
}
.pointer {
  cursor: pointer;
}
.inner {
	margin: 0 auto;
	padding: 5px 20px;
	max-width: 1170px;
}
.devider {
	margin-bottom: 9px;
	width: 100%;
	text-align: center;
	font-size: .9em;
	span {
		background: white;
		padding: 2px 7px;
	}
	&:after {
		display: block;
		margin-top: -9px;
		background: rgba(140,140,140,0.42);
		width: 100%;
		height: 1px;
		content: "";
	}
}
.no-list-style {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
	}
}
iframe {
	max-width: 100%;
}
.left-side {
	padding-left: 0;
}
.right-side {
	padding-right: 0;
}
.centered-text {
	text-align: center;
}
.col-md-6 {
	@media screen and (max-width: 991px) {
		padding-right: 0;
		padding-left: 0;
	}
}
.bottom-margin {
	margin-bottom: 1em;
}
.flex-columns {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	@media screen and (max-width: 800px) {
		&.two-columns-with-button {
			& > .button-container, &.button-container {
				text-align: center;
				margin-bottom: 1em;
				&:last-of-type {
					margin-bottom: 0;
				}
				button {
					margin-bottom: 1em;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}


	@media screen and (min-width: 801px) {
		flex-direction: row;
		&.two-columns {
			& > div, & > form, & > li, & > lib-form-field {
				width: 48%;
			}
		}
		&.two-columns-with-button {
			& > div, & > form, & > li, & > lib-form-field {
				flex-grow: 1;
				margin-right: 2em;
				width: auto;
				&.button-container {
					flex-grow: 0;
					margin-right: 0;
					button {
						margin-right: 1em;
						&:last-of-type {
						margin-right: 0;
						}
					}
				}
			}
		}
		&.right-aligned-inline-form {
			justify-content: flex-end;
			& > div, & > form, & > li, & > lib-form-field {
				flex-grow: 1;
				margin-right: 2em;
				max-width: 500px;
				width: auto;
				&.button-container {
					flex-grow: 0;
					margin-right: 0;
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					button {
						margin-right: 1em;
						&:last-of-type {
						margin-right: 0;
						}
					}
				}
			}
		}
		&.bottom-aligned {
			align-items: flex-end;
		}
		&.center-aligned {
			align-items: center;
		}
	}
	& > div, & > form, & > li {
		width: 100%;
	}
}
.flex-stretch {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.col-md-12.col-sm-12.codeSide {
	border-radius: 10px;
	background: #272822;
	padding-top: 7px;
	padding-left: 21px;
	height: 40em;
}
.note {
	color: orange !important;
	font-size: 1em;
	font-style: italic;
}
ul.no-margins {
	margin: 0;
	padding: 0;
}
li.no-list {
	list-style: none;
}
.app {
	height: auto;
}
.border-bottom-devider {
	margin-bottom: 1em;
	border-bottom: 1px solid rgba(204, 204, 204, 0.25);
	padding-bottom: 0.5em;
}


// NEW ELEMENTS ADDED

// layout elements for content pages

.space-filler {
	position: relative;
	width: 100%;
	height: 50vh;
	&.blue-gradient {
		background: linear-gradient(-29deg, black 4%, #243a62a6, #18356a8c, #1f1f1f), linear-gradient(23deg, #070e1c, #0c1931, #77a7ff29);
	}
}

.image-link, .expand-link, .info-link {
	position: relative;
	cursor: pointer;
	&:after {
		content: "\f0c1";
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
		font-family: FontAwesome;
		font-size: 1.75em;
		color: #ffffff;
		transition: all .15s linear;
		opacity: 0.5;
		transform: scale(1);
		text-shadow: 2px 2px 5px #000000cf;
		background: #8080809c;
		width: 40px;
		height: 40px;
		text-align: center;
		border-radius: 5px;
	}
	&:hover, &:focus {
		&:after {
			opacity: .75;
		}
	}
}
.expand-link:after {
	content: "\f424";
}
.info-link:after {
	content: "\f129";
}

.page, .content-page-sections section {
	&:last-of-type {
		margin-bottom: 6em;
	}
	.page-inner {
		margin: 0 auto;
		max-width: 1200px;
		padding: 20px;

		h3 {
			font-size: 1.75rem;
			text-align: center;
			margin-bottom: 1em;
			font-family: "NunitoSans-Light";
			color: white;
		}
	}
}

.centered-table-list {
	padding: 0;
	width: 90%;
	max-width: 600px;
	margin: 0 auto 4em;

	h3 {
		text-align: center;
		margin-bottom: 1.5em;
		font-size: 1.5em;
	}

	ul {
		display: table;
		width: auto;
		margin: 0 auto;
		padding: 0;

		li {
			display: table-row;
			list-style: none;
			width: 90%;
			margin: 0 auto 1em;
			justify-content: space-between;
			align-items: center;

			& > * {
				display: table-cell;
				width: auto;
				padding: 0 .5em;
				font-size: 1.25em;
			}

			span {
				text-align: right;
				color: white;
			}

			div {
				text-align: left;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		h3 {
			font-size: 1.675em;
		}
		ul {
			font-size: 0.925em;
		}
	}
	@media screen and (max-width: 800px) {
		h3 {
			font-size: 1.5em;
		}
		ul {
			font-size: 0.875em;
		}
	}
	@media screen and (max-width: 600px) {
		h3 {
			font-size: 1.425em;
		}

	}
	@media screen and (max-width: 480px) {
		h3 {
			font-size: 1.25em;
		}
		ul li {
			display: block;
			text-align: center;
			margin-bottom: 2em;
			width: auto;
			span {
				width: auto;
				text-align: center;
			}
			div {
				width: auto;
				font-size: 1.375em;
			}
		}
	}
	@media screen and (max-width: 320px) {
		h3 {
			font-size: 1.125em;
		}

	}
}

.transparetn-block-columns {
  display: flex;
  gap: 5%;
  align-items: stretch;
  padding: 0 1em;
  & > div {
    width: 50%;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  .transparent-block .transparent-block-text p {
    @media screen and (max-width: 1000px) {
      font-size: 1.125em;
    }
  }

  button.secondary {
    @media screen and (max-width: 1000px) {
      font-size: 0.875em;
      font-family: NunitoSans;
    }
  }


  h3 {
    font-size: 3em;
    margin-bottom: 1em;
    text-align: left;
    @media screen and (max-width: 1400px) {
      font-size: 2.5em;
    }
    @media screen and (max-width: 1200px) {
      font-size: 2.375em;
    }
    @media screen and (max-width: 1000px) {
      font-size: 2em;
    }
    @media screen and (max-width: 1000px) {
      margin-bottom: 1em;
    }
  }
}

.transparent-block {
	position: relative;

	width: 90%;
	max-width: 1200px;
	margin: 0 auto 8em;
	padding: 40px;
	overflow: hidden;

	display: flex;
	justify-content: space-around;
	align-items: stretch;

	background: linear-gradient(115deg, #c1cbea0f, #0a265a45, #dadada36);
	border: #00538a2e solid 1px;
	box-shadow: 0px 6px 13px -5px black;

  // @media screen and (max-width: 1000px) {
  //   padding: 25px;
  // }

  h5 {
    line-height: 1.75;
    @media screen and (max-width: 800px) {
      font-size: 1em;
    }
  }

  ul {
    margin: 2em 0;
    li {
      color: white;
      font-size: 1.25em;
      margin-bottom: 0.5em;
      @media screen and (max-width: 800px) {
        font-size: 1em;
      }
      ul {
        margin: 0.5em 0 1em;
        li {
          font-size: 0.9125em;
          list-style: disc;
        }
      }
    }
  }

  .transparetn-block-columns & {
    margin: 0;
    width: 50%;
    @media screen and (max-width: 800px) {
      width: 90%;
      margin: 5% auto;
    }
  }


  &.narrow {
    max-width: 1000px;
    width: 80%;
    &.shifted-right {
      right: -5%;
    }
    &.shifted-left {
      left: -5%;
    }
  }

	&:last-of-type {
		margin-bottom: 0;
	}

	&.flipped-bg {
		background: linear-gradient(295deg, #c1cbea0f, #0a265a45, #dadada36);
	}

  	&:before {
    	content: "";
    	position: absolute;
		top: 0;
		left: 0;
		width: 80%;
		height: 92%;
		display: block;
		transform: rotate(-115deg) translate(-19%, 90%);
		background: linear-gradient(180deg, rgba(111, 122, 150, 0.04), transparent 50%);
		z-index: -1;
  	}

	&.style-1-right:before {
		background: linear-gradient(180deg, rgba(111, 122, 150, 0.04), transparent 50%);
		transform: rotate(-115deg) translate(-19%, 90%);
	}
	&.style-1-left:before {
		background: linear-gradient(180deg, rgb(24 30 45 / 31%), transparent 20%);
		transform: rotate(125deg) translate(4%, 55%);
	}
	&.style-2-left:before {
		background: linear-gradient(180deg, hsl(223deg 26% 5% / 50%), transparent 20%);
		transform: rotate(145deg) translate(4%, 53%);
	}
	&.style-2-right:before {
		background: linear-gradient(180deg, rgb(111 122 150 / 4%), transparent 50%);
		transform: rotate(-130deg) translate(-18%, 138%);
	}
  // border-top: 1px solid #3f82ff38;

  &.sub-blocks {
    flex-direction: column;

    .horizontal-line {
      width: 80%;
      height: 2px;
      background: linear-gradient(90deg,transparent, #78a8ff80, transparent);
      margin: 0 auto 1em;
      padding: 0;
    }
    .sub-block {
      display: flex;
      justify-content: space-around;
      align-items: stretch;
      // margin-bottom: 3em;
      margin-top: 2em;
      padding-bottom: 3em;
      margin-bottom: 1em;

      & > div {
        flex-grow: 1;
        flex-shrink: 0;
      }
      h2 {
        margin-top: 0.25em;
      }
      h5 {
        font-size: 1.25em;
        font-family: NunitoSans-ExtraLight;
        margin: 0.75em 0 0.5em;
      }
      .text-button {
        margin-left: -.5em;
      }

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 2em;
      }
      &.text-and-button {
        justify-content: space-evenly;
        align-items: center;
        h4 {
          font-family: "NunitoSans-Light";
          color: #77a7ff;
          text-align: center;
          line-height: 1.5;
          margin-right: 5%;
          font-size: 1.625em;
          margin-bottom: 0;
        }
        button, a[role=button] {
          flex-shrink: 0;
          flex-shrink: 0;
          font-size: 1.25em;
          font-family: "NunitoSans-SemiBold";
          text-transform: none;
          white-space: nowrap;
        }
        &.even-layout {
          justify-content: space-between;
          & > h4 {
            flex-grow: 1;
          }
          & > a {
            min-width: 9em;
          }
        }
        @media screen and (max-width: 799px) {
          flex-direction: column;
          h4 {
            margin-right: 0;margin-bottom: 1.5em;
          }
        }
      }
      &.icon-top-left {
        fa-icon {
          font-size: 1.5em;
        }
      }
    }
    &.sub-blocks-side-by-side {
      .sub-block > div {
        flex-shrink: 1;
      }
      @media screen and (min-width: 801px) {
        flex-direction: row;
        position: relative;
        justify-content: space-between;
        align-items: stretch;
        .horizontal-line {
          width: 2px;
          height: 80%;
          left: 50%;
          position: absolute;
          background: linear-gradient(0deg,transparent, #78a8ff80, transparent);
          background-size: contain;
        }
        .sub-block {
          width: 50%;
          margin: 0;
          padding: 0;
          flex-shrink: 0;
          & > div {
            flex-shrink: 1;
          }
          &:first-of-type {
            padding-right: 5%;
          }
          &:last-of-type {
            padding-left: 5%;
          }
        }
      }

      @media screen and (max-width: 1000px) and (min-width:801px) {
        width: 100%;
      }


    }
  }

	.transparent-block-image {
		width: auto;
		min-width: 40%;
		max-width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			max-width: 100%;
			transform: scale(1);
			opacity: .75;
			transition: transform .25s ease-out, opacity .35s ease;
			&:hover {
				transform: scale(1.05);
				opacity: 1;
			}
		}

		a {
			position: relative;

			// &:after {
			// 	content: "\f424";
			// 	position: absolute;
			// 	font-family: "FontAwesome";
			// 	top: 0;
			// 	left: 0;
			// 	width: 100%;
			// 	height: 100%;
			// 	// opacity: 0;
			// 	transition: opacity 0.25s ease-out;
			// 	// background: radial-gradient(#ffffff,transparent 50%);
			// }
			// &:hover, &:focus {
			// 	&::after {
			// 		opacity: .25;
			// 	}
			// }
		}
	}

	.transparent-block-text {
		width: auto;
		padding: 0 3em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		text-align: center;
    &.space-evenly {
      justify-content: space-evenly;
    }
    &.space-between {
      justify-content: space-between;
    }
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
    h5 {
      text-align: left;
    }

		ul {
			li {
				text-align: left;
			}
		}

    img {
      width: 90%;
      margin: 0 auto 2em;
      &.arrow-image {
        max-width: 80px;
        margin: 0em 0 0.5em;
      }
    }

		h2 {
			display: inline-block;
			margin: 0 0 .5em;
			font-family: "NunitoSans-ExtraLight";
			font-size: 2em;
			color: #77a7ff;
      text-align: left;

      text-transform: uppercase;

			a {
				transition: color .15s ease-out;
			}
		}
    h3 {
      text-transform: uppercase;
    }
		p {
			margin-bottom: 1.5em;
			font-size: 1.25em;
			text-align: left;
			font-family: "NunitoSans-Light";
			letter-spacing: 0.25px;
			color: white;
			&:last-child {
				margin-bottom: 0;
			}
		}
		ul:last-child {
			margin-bottom: 0;
		}
		p + ul {
			margin-top: -1em;
		}
		button + a {
			margin-top: 0.75em;
		}
	}

	&.text-and-button {
		justify-content: space-evenly;
		align-items: center;
		h4 {
			font-family: "NunitoSans-Light";
			color: #77a7ff;
			text-align: center;
			// padding: 1em 4em;
			line-height: 1.5;
			// width: 60%;
			margin-right: 5%;
			font-size: 1.625em;
			margin-bottom: 0;
		}
		button {
			flex-shrink: 0;
		}
		a.secondary {
			flex-shrink: 0;
			font-size: 1.25em;
			font-family: 'NunitoSans-SemiBold';
			text-transform: none;
      white-space: nowrap;
		}
	}

	@media screen and (max-width: 1400px) {
		padding: 40px;
		.transparent-block-text h2 {
			font-size: 1.75em;
		}
	}
	@media screen and (max-width: 1200px) {
		.transparent-block-text h2 {
			font-size: 1.625em;
		}
	}
	@media screen and (max-width: 1000px) {
    padding: 25px;
		.transparent-block-text h2 {
			font-size: 1.5em;
		}
	}
	@media screen and (max-width: 800px) {
		flex-direction: column-reverse;
    padding: 6%;
		.transparent-block-image {
			width: auto;
			max-width: 80%;
			margin: 1.5em auto 2em;
			// height: 250px;
		}
		.transparent-block-text {
			width: auto;
			margin-bottom: 2em;
			margin-top: 1em;
			&:last-child, &:first-child {
				padding: 0 3em;
        @media screen and (max-width: 800px) {
          padding: 0;
        }
			}

      &.single-block {
        margin: 0;padding: 0;
      }

			h2 {
				margin-bottom: 1.25em;
			}

			p {
				text-align: justify;
			}
		}
		&.flipped-bg {
			flex-direction: column;
		}

    &.text-and-button {
      flex-direction: column;
      h4 {
        margin-bottom: 1em;
      }
    }

		&:before {
			width: 90%;
			height: 100%;
		}
		&.style-1-right:before {
			transform: rotate(-115deg) translate(-3%, 89%);
		}
		&.style-1-left:before {
			width: 150%;
    		height: 100%;
			transform: rotate(125deg) translate(13%, 86%);
		}
	}
	@media screen and (max-width: 680px) {
		.transparent-block-text {
			&:last-child, &:first-child {
				// padding: 0 2em;
			}
			p, ul {
				font-size: 1.0625em;
			}
		}
		.transparent-block-image {
			min-width: 90%;;
		}
	}
	@media screen and (max-width: 520px) {
		padding: 40px 20px;
	}
	@media screen and (max-width: 480px) {
		.transparent-block-text {
			h2 {
				font-size: 1.375em;
			}
			p, ul {
				font-size: 1em;
			}
		}
	}
	@media screen and (max-width: 420px) {
		padding: 30px 15px;
		width: 94%;
		.transparent-block-text {
			&:last-child, &:first-child {
				padding: 0 1em;
			}
		}
	}
	@media screen and (max-width: 360px) {
		padding: 20px 10px;
		width: 100%;
		.transparent-block-text {
			h2 {
				font-size: 1.25em;
			}
			p, ul {
				font-size: 0.875em;
			}
		}
	}
}

// boxes with animated percentage numbers and circles
.percetage-boxes {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
	margin-bottom: 2em;

	li {
        width: 30%;
        margin: 0;
        padding: 1.5em 1em 1em;

		display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

		background: linear-gradient(115deg, #c1cbea0f, #0a265a45, #dadada36);
        border: #00538a2e solid 1px;
        box-shadow: 0px 6px 13px -5px black;
        opacity: 0.2;

		transform: translate(0,10px);
        transition: all .35s ease-out;

		&.active {
            transform: translate(0,0);
            opacity: 1;
        }
        fa-icon {
            color: #77a7ff;
            font-size: 2.75em;
            display: none;
        }

		.circle {
            width: 150px;
            height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            position: relative;
            .arc {
                position: absolute;
                overflow: hidden;
                /* make sure top & left values are - the width of the border */
                /* the bottom right corner is the centre of the parent circle */
                top: -1em;
                right: 50%;
                bottom: 50%;
                left: -1em;
                /* the transform origin is the bottom right corner */
                transform-origin: 100% 100%;
                /* rotate by any angle */
                /* the skew angle is 90deg - the angle you want for the arc */
                transform: rotate(45deg) skewX(30deg);

                &:before {
                    box-sizing: border-box;
                    display: block;
                    border: solid 1em navy;
                    width: 200%;
                    height: 200%;
                    border-radius: 50%;
                    transform: skewX(-30deg);
                    content: '';
                }
            }
            .number {
                font-family: 'NunitoSans-ExtraLight';
                font-size: 3em;
            }
            .text {
                font-family: 'NnunitoSans-Light';
                font-size: 1.5em;
            }
        }

		.desc {
			padding-top: 1em;
            height: 6em;
            display: flex;
            justify-content: center;
            align-items: center;
			h3 {
                font-family: 'NunitoSans-ExtraLight';
                color: #77a7ff;
                text-align: center;
				margin-bottom: 0;
                @media screen and (max-width: 1200px) {
                    font-size: 1.675em;
                }
                @media screen and (max-width: 1200px) {
                    font-size: 1.5em;
                }
            }
		}
	}

	@media screen and (max-width: 1000px) {
        li {
            width: 30%;
            padding: 1.25em 1em 0.5em;
        }

    }
    @media screen and (max-width: 800px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        li {
            flex-direction: row;
            width: 100%;
            margin: 2em 0;
            .circle {
                width: 48%;
                justify-content: center;
                align-items: flex-end;
            }
            .desc {
                width: 48%;
                justify-content: start;
                text-align: left;
                padding-left: 0;
                margin-left: 4%;
            }
        }
    }
    @media screen and (max-width: 600px) {
        li {
            .circle {
                width: 40%;
            }
            .desc {
                width: auto;
                flex-grow: 1;
                margin-left: 6%;
                height: auto;
                h3 {
                    text-align: left;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        li .desc h3 {
            font-size: 1.425em;
        }
    }
    @media screen and (max-width: 480px) {
        li {
            flex-direction: column;
            .circle {
                width: 100%;
                align-items: center;
            }
            .desc {
                margin-left: 0;
            }
        }
    }
}

.flex-boxes-container {
    margin: 1em auto;
    h3 {
        font-size: 1.5em;
        margin-bottom: 1.5em;
        text-align: center;
    }
}

.three-boxes-with-icons {
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
	margin: 2em 0 3em;
    @media screen and (max-width: 800px) {
        align-items: center;
		flex-direction: column;
    }

	.box {
    position: relative;
    width: 31%;
    height: auto;
    padding: 0;
    padding-bottom: 25%;
    border-radius: 0px;

		@media screen and (max-width: 800px) {
      width: 80%;
      height: 175px;
      margin-bottom: 3em;
      padding-bottom: 0;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    @media screen and (max-width: 480px) {
      height: 160px;
    }

    .box-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(115deg, #c1cbea0f, #0a265a45, #dadada36);
      border: #00538a2e solid 1px;
      box-shadow: 0px 6px 13px -5px black;
      transition: all 0.15s ease-in-out;
      overflow: hidden;
    }

    .box-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 1.25em;
        text-align: center;
        padding: 0 0.5em;
        margin: 0;
        line-height: 1.75;
        color: white;
        font-family: NunitoSans-ExtraLight;
        @media screen and (max-width: 1200px) {
          font-size: 1.125em;
        }
        @media screen and (max-width: 1000px) {
          font-size: 1em;
        }
        @media screen and (max-width: 900px) {
          font-size: 0.875em;
        }
        @media screen and (max-width: 800px) {
          font-size: 1.25em;
        }
        @media screen and (max-width: 600px) {
          font-size: 1.125em;
        }
        @media screen and (max-width: 480px) {
          font-size: 1em;
        }
      }
    }

		a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      cursor: pointer;
      overflow: hidden;
      background: linear-gradient(115deg, #c1cbea0f, #0a265a45, #dadada36);
      border: #00538a2e solid 1px;
      box-shadow: 0px 6px 13px -5px black;
      transition: all 0.15s ease-in-out;

			img {
        position: absolute;
        top: 10%;
        right: 10%;
        width: 35%;
        opacity: 0.1;
        transition: all 0.15s ease-in-out;
      }

			&:hover, &:focus {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.788);
        transform: scale(1.015) translate(-4px,-8px);
        box-shadow: 3px 8px 13px 6px #0000008a;
        img {
            opacity: 0.2;
        }
      }
		}

		& a:before, & .box-background:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 134%;
      height: 93%;
      background: linear-gradient(180deg, rgb(17 26 47 / 53%), transparent 20%);
      transform: rotate(145deg) translate(4%, 53%);
      z-index: 0;
    }
    &:nth-child(1) a:before, &:nth-child(1) .box-background:before {
        transform: rotate(150deg) translate(16%, 25%);
        @media screen and (max-width: 800px) {
            transform: rotate(250deg) translate(16%, -15%);
            background: linear-gradient(180deg, rgb(11 16 27 / 51%), transparent 20%);
        }
    }
    &:nth-child(2) a:before, &:nth-child(2) .box-background:before {
        transform: rotate(150deg) translate(16%, 98%);
        background: linear-gradient(180deg, rgb(14 15 19 / 51%), transparent 20%);
        @media screen and (max-width: 800px) {
            transform: rotate(250deg) translate(16%, 28%);
        }
    }
    &:nth-child(3) a:before, &:nth-child(3) .box-background:before {
        transform: rotate(218deg) translate(8%, 38%);
        background: linear-gradient(180deg, rgb(13 17 27 / 47%), transparent 20%);
        @media screen and (max-width: 800px) {
            transform: rotate(290deg) translate(8%, 30%);
        }
    }

		h4 {
            margin-top: 1em;
            padding: 0 1em;
            font-family: 'NunitoSans';
            font-size: 1.5em;
            text-align: left;
            @media screen and (max-width: 1000px) {
                font-size: 1.375em;
            }
            @media screen and (max-width: 850px) {
                padding: 0 0.75em;
                font-size: 1.25em;
            }
            @media screen and (max-width: 800px) {
                font-size: 1.5em;
            }
            @media screen and (max-width: 600px) {
                font-size: 1.375em;
            }
            @media screen and (max-width: 480px) {
                font-size: 1.25em;
            }
        }

		p {
            padding: 0 1.25em;
            font-size: 1.125em;
            color: #a7ceff;
            @media screen and (max-width: 1000px) {
                font-size: 1em;
            }
            @media screen and (max-width: 850px) {
                margin-bottom: 0.75em;
                padding: 0 1em;
                font-size: 0.9375em;
            }
            @media screen and (max-width: 800px) {
                font-size: 1.125em;
            }
            @media screen and (max-width: 600px) {
                font-size: 1.0625em;
            }
            @media screen and (max-width: 480px) {
                font-size: 1em;
            }
        }
	}
  &.four-boxes {
    .box {
      width: 22%;
      padding-bottom: 21%;
      @media screen and (max-width: 800px) {
        width: 60%;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      @media screen and (max-width: 480px) {
          height: 160px;
      }
    }
  }
}

.no-wrap {
	white-space: nowrap;
}

.wrap-if-needed {
	display: inline-block;
}

.bold {
	font-family: 'NunitoSans-Bold';
}


.authLayout {
	background: url(/assets/images/NineGold_banner_1500-xlg.jpg) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    position: relative;
    height: auto;
	display: flex;
	flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
	& > header {
		display: block;
		flex-shrink: 0;
		z-index: 2;
		text-align: center;
		img {
			width: 80%;
			// min-width: 200px;
			max-width: 350px;
			padding: 20px 0;
		}
	}
	& > main, & > .site-container {
		font-size: 1rem;
		background: white;
		padding: 2em;
		/* margin: 2em; */
		// border-top: 5px solid #3f82ff;
		margin: 0 auto;
		box-shadow: var(--pf-global--BoxShadow--lg);
		max-width: 600px;
		width: 80%;
		// border-top: 5px solid;
		// border-color: #3f82ff;
		z-index: 2;
		margin: 0 0 20px;
		padding: 20px 40px 30px 40px;
		position: relative;

		&:before {
			content: "";
			display: block;
			top: 0;
			left: 0;
			position: absolute;
			z-index: 2;
			height: 5px;
			width: 100%;
			background: linear-gradient(90deg, #3f82ff, #77a7ff);
		}

		@media screen and (max-width: 680px) {
			padding: 15px 30px 30px 30px;
			font-size: 0.9375em;
			width: 84%;
			// padding: 15px 20px 20px 20px;
		}
		@media screen and (max-width: 480px) {
			// padding: 15px 30px 30px 30px;
			padding: 15px 20px 20px 20px;
			font-size: 0.875em;
			width: 86%;
		}
		@media screen and (max-width: 360px) {
			width: 90%;
		}

		.border {
			border: 1px solid #e6e6e6 !important;
			padding: 0.5em 0.75em;
			margin-bottom: 1em;
		}

		.blue-highlight {
			color: #3f82ff;
			color: #1747a2;
			font-family: 'NunitoSans-SemiBold';
		}

		h1 {
			color: #363636;
			font-family: 'NunitoSans-SemiBold';
			font-size: 2em;
			margin-top: 20px;
			margin-bottom: 10px;
			text-align: center;
		}
		h2 {
			color: #363636;
			font-size: 1.25em;
			margin-bottom: 2em;
		}
		h3 {
			color: #3f82ff;
			font-family: 'NunitoSans-ExtraLight';
			font-weight: normal;
			font-size: 1.25em;
		}
		p {
			font-size: 1.125em;
		}
		a.text-button {
			padding: 0;
			height: auto;
			min-width: auto;
		}
		section {
			margin: 2em 0;
		}
		.label {
			font-family: 'calibri';
			margin-right: 0.5em;
			font-weight: normal;
			&:after {
				content: ":"
			}
		}
		.value {
			font-family: 'NunitoSans-SemiBold';
			&.ellipsis {
				text-overflow: ellipsis;
				padding-right: 2em;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}
	&:before {
		content: "";
		top: 0;
		position: absolute;
		width: 100%;
		height: 50%;
		background: linear-gradient(180deg,#0000008c, transparent);
	}
	&:after {
		content: "";
		bottom: 0;
		position: fixed;
		width: 100%;
		height: 50%;
		background: linear-gradient(0deg,#0000008c, transparent);
	}
}
