// Animations for this partial
@keyframes rotate-icon {
    0% {
        transform: rotate3d(0,0,0,0deg);
    }
    100% {
        transform: rotate3d(0,0,1,360deg);
    }
}

.blue-underline {
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 20%;
    background: url(/assets/images/blue-underline.png);
    display: block;
    left: 0%;
    bottom: -0.25em;
    position: absolute;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

.content-wrapper {
    position: relative;
    overflow: hidden;
    // background gradient to be replaced by each homepage

    & > svg {
        // rotating NG icon
        position: absolute;
        top: 85vh;
        right: -16%;
        width: 79vw;
        height: auto;
        opacity: .08;
        z-index: 0;
        animation: rotate-icon 300s linear infinite;
        transform-origin: 50% 53.85%;
        // -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
        // filter: drop-shadow( -9px -10px 11px rgba(0, 0, 0, .7));
    }

    & > .page-content {
        z-index: 1;
        position: relative;

        & > header {
            height: 65vh;
            background-size: cover;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: 50%;
            @media screen and (max-width: 1000px) {
                height: 60vh;
            }
            @media screen and (max-height: 800px) {
                height: 60vh;
            }

            @media screen and (max-width: 700px) {
                height: 55vh;
            }
            @media screen and (max-height: 650px) {
                height: 55vh;
            }

            @media screen and (max-width: 500px) {
                height: 50vh;
            }
            @media screen and (max-width: 360px) {
                height: 45vh;
            }



            .banner-inner {
                position: relative;
                display: flex;
                width: 100%;
                height: 100%;
                padding: 0;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: radial-gradient(rgb(0 0 0 / 17%), rgb(0 0 0 / 45%), #000000d6 75%), linear-gradient(0deg, #00000066, transparent 20%),linear-gradient(#0000007a, transparent);

                h1, p {
                    color: white;
                    max-width: 90%;
                }

                h1 {
                    position: relative;
                    margin-bottom: 0;

                    font-family: "pirulen";
                    font-size: 5em;
                    text-transform: uppercase;
                    text-align: right;
                    line-height: 1;
                    letter-spacing: 6px;
                    // text-shadow: 4px 8px 10px black;
                    text-shadow: 0.05em 0.1em 0.125em black;
                    span {
                        display: block;
                        margin-right: 0.25em;
                        margin-top: -0.25em;
                        font-family: "NunitoSans-LightItalic";
                        font-size: 0.5em;
                        font-weight: normal;
                        text-transform: lowercase;
                        letter-spacing: 1px;
                    }

                    span.registered-sign {
                        display: inline-block;
                        vertical-align: super;
                        margin-right: -0.375em;
                        font-family: "NunitoSans-LightItalic";
                        font-size: .75em;
                        font-weight: normal;
                        text-transform: lowercase;
                    }

                    @media screen and (max-width: 1400px) {
                        font-size: 4.25em;
                    }
                    @media screen and (max-width: 1350px) {
                        font-size: 4em;
                    }
                    @media screen and (max-width: 1300px) {
                        font-size: 3.75em;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 3.5em;
                    }
                    @media screen and (max-width: 1100px) {
                        font-size: 3.25em;
                    }
                    @media screen and (max-width: 1000px) {
                        font-size: 3em;
                    }
                    @media screen and (max-width: 900px) {
                        font-size: 2.875em;
                    }
                    @media screen and (max-width: 800px) {
                        font-size: 2.75em;
                        letter-spacing: 5px;
                    }
                    @media screen and (max-width: 700px) {
                        font-size: 2.5em;
                    }


                    @media screen and (max-width: 600px) {
                        font-size: 2.25em;
                        letter-spacing: 4px;
                        margin-bottom: 0.675em;
                        span {
                            font-size: 0.675em;
                            margin-top: -0.125em;
                        }
                    }
                    @media screen and (max-width: 500px) {
                        font-size: 2em;
                    }
                    @media screen and (max-width: 450px) {
                        font-size: 1.875em;
                        letter-spacing: 3px;
                    }
                    @media screen and (max-width: 400px) {
                        font-size: 1.75em;
                        letter-spacing: 2.5px;
                        margin-bottom: 0.75em;
                    }
                    @media screen and (max-width: 360px) {
                        font-size: 1.675em;
                        letter-spacing: 2px;
                    }
                    @media screen and (max-width: 320px) {
                        font-size: 1.5em;
                        letter-spacing: 1.5px;
                    }
                    @media screen and (max-width: 280px) {
                        font-size: 1.375em;
                        letter-spacing: 1px;
                    }
                }
            }
        }

        & > main {
            background-size: 100% 30%;
            background-repeat: no-repeat;
            color: white;
            padding-bottom: 3em;

            & > section {
                width: 100%;
                margin-bottom: 5em;
                @media screen and (max-width: 800px) {
                    margin-bottom: 4em;
                }
                @media screen and (max-width: 600px) {
                    margin-bottom: 2.5em;
                }
                @media screen and (max-width: 480px) {
                    margin-bottom: 1.5em;
                }
                @media screen and (max-width: 420px) {
                    margin-bottom: 0;
                }
                &.more-bottom-margin {
                  margin-bottom: 8em;
                  @media screen and (max-width: 800px) {
                    margin-bottom: 6em;
                  }
                  @media screen and (max-width: 600px) {
                      margin-bottom: 3.5em;
                  }
                  @media screen and (max-width: 480px) {
                      margin-bottom: 2.5em;
                  }
                  @media screen and (max-width: 420px) {
                      margin-bottom: 0.5em;
                  }
                }
                &.more-top-margin {
                  margin-top: 8em;
                  @media screen and (max-width: 800px) {
                    margin-top: 6em;
                  }
                  @media screen and (max-width: 600px) {
                      margin-top: 3.5em;
                  }
                  @media screen and (max-width: 480px) {
                      margin-top: 2.5em;
                  }
                  @media screen and (max-width: 420px) {
                      margin-top: 0.5em;
                  }
                }
                .section-inner {
                    width: 100%;
                    height: 100%;
                    max-width: 1400px;
                    margin: 0 auto;
                    padding: 20px;

                    & > h2 {
                        width: 80%;
                        max-width: 1400px;
                        margin: 1.25em auto 3em;
                        font-family: "NunitoSans-Light";
                        font-size: 2em;
                        line-height: 1.5;
                        text-align: center;
                        color: #d8e6f2;
                        letter-spacing: 0.025em;

                        &.uppercase {
                          text-transform: uppercase;
                        }

                        &.no-bottom-margin {
                          margin: 2.5em auto 1em;
                        }

                        @media screen and (max-width: 1400px) {
                            font-size: 1.875em;
                        }
                        @media screen and (max-width: 1200px) {
                            font-size: 1.75em;
                        }
                        @media screen and (max-width: 1000px) {
                            font-size: 1.625em;
                        }
                        @media screen and (max-width: 800px) {
                            font-size: 1.5em;
                        }
                        @media screen and (max-width: 600px) {
                            font-size: 1.375em;
                        }
                        @media screen and (max-width: 480px) {
                            font-size: 1.25em;
                        }
                        @media screen and (max-width: 420px) {
                            font-size: 1.125em;
                        }
                        @media screen and (max-width: 420px) {
                            font-size: 1.0625em;
                            padding-top: 1em;
                            line-height: 1.75;
                        }
                    }
                }
                &.headquote-section {
                    // height: calc(43vh - 60px);
                    padding-top: 4%;
                    margin-bottom: 7%;
                    .section-inner {
                        max-width: 1400px;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        flex-direction: column;
                        h2 {
                            margin: 0;
                        }
                        ul {
                          margin: 3% auto 0;
                          padding: 0 0 0 1em;
                          max-width: 70%;
                          li {
                            margin: 0.5em 0;
                            font-size: 1.5em;
                            padding-left: 1em;
                            color: #d8e6f2;
                            letter-spacing: 0.025em;
                            @media screen and (max-width: 1400px) {
                              font-size: 1.4375em;
                            }
                            @media screen and (max-width: 1200px) {
                                font-size: 1.375em;
                            }
                            @media screen and (max-width: 1000px) {
                                font-size: 1.3125em;
                            }
                            @media screen and (max-width: 800px) {
                                font-size: 1.25em;
                            }
                            @media screen and (max-width: 600px) {
                                font-size: 1.125em;
                            }
                            @media screen and (max-width: 480px) {
                                font-size: 1em;
                            }
                            // @media screen and (max-width: 420px) {
                            //     font-size: 1.125em;
                            // }
                            // @media screen and (max-width: 420px) {
                            //     font-size: 1.0625em;
                            //     padding-top: 1em;
                            //     line-height: 1.75;
                            // }

                            &::marker {
                              content: "\f00c";
                              color: #77a7ff;
                              font-family: "fontawesome";
                              font-size: 1em;
                              position: absolute;
                              left: -1.5em;
                            }
                          }
                        }
                    }
                    // @media screen and (max-width: 1000px) {
                    //     height: calc(40vh - 60px);
                    // }
                    // @media screen and (max-height: 800px) {
                    //     height: calc(40vh - 60px);
                    // }

                    // @media screen and (max-width: 700px) {
                    //     height: calc(45vh - 60px);
                    // }
                    // @media screen and (max-height: 650px) {
                    //     height: calc(45vh - 60px);
                    // }

                    // @media screen and (max-width: 500px) {
                    //     height: calc(50vh - 60px);
                    // }
                    // @media screen and (max-width: 420px) {
                    //     height: auto;
                    // }
                }
                &.boxes-section .section-inner {
                    padding: 5px 0px;
                    width: 86%;
                }
            }
        }


    }
}
