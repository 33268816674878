$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

$breakpoint-mobile-main-nav: 800px;
$breakpoint-mobile-medium-icons: 600px;
$breaipoint-mobile-small: 480px;
$breakpoint-mobile-min: 360px;
$breakpoint-desktop-main-nav-min: 801px;
$breakpoint-small-desktop: 1400px;
$breakpoint-demo-nav: 1100px;


.content-container .site-container .left-sidebar-container .left-sidebar {
  opacity: 0;
  // transition: opacity .5s ease 1s;
}

.app-layout {
    height: 100vh;
    top: 0;
    position: absolute;
    width: 100%;
    body.status-bar & {
      top: 34px;
      height: calc(100vh - 34px);
    }
    .site-container {
        height: 100%;
        .left-sidebar-container {
            position: absolute;
            display: flex;
            width: 100%;
            height: auto;
            min-height: 100%;
            top: 0;
            padding-top: 2.5em;
            // padding-top: $appViewHeaderHeight;
            background: linear-gradient(0deg, #d5dde0, #f1f6f7 6%, #f1f6f7 94%, #aeb5b8 97%, #aeb5b8);
            transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
            transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);

            p {
                padding:  1.25em;
            }

            .done fa-icon {
                color: #3d73d7;
            }
            .disabled {
                opacity: .5;
            }

            h5 {
                color: #e9ecef;
            }

            .left-sidebar {
                // transition: opacity .1s ease 0;
                opacity: 1;
                display: flex;
                flex-direction: column;
                position: fixed;
                width: calc(25vw - 3.125em);
                padding: 0px;
                height: calc(100vh - #{$appViewHeaderHeight});
                background: #30333b;
                background: linear-gradient(115deg, #101010, #1f272f, #101010);
                color: white;
                min-width: 12.5em;
                max-width: 18.75em;
                // padding-top:  3.125em;
                top: 0;
                height: 100vh;
                overflow: visible;
                transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
                transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);

                transition: all 0.5s ease-in-out 0.05s;
                z-index: 10;

                .closed-sidebar-item {
                    display: none;
                }
                .open-sidebar-item {
                    display: block;
                }

                .sidebar-logo .logo {
                    justify-content: center;
                    padding: 5px;
                    left: 0;
                    width: calc(25vw - 3.125em);
                    // min-width:  12.5em;
                    min-width: 12.5em;
                    max-width: 18.75em;
                    // max-width:  18.75em;
                    // background: rgb(48 51 59 / 83%);
                    transition: none;

                    // border-bottom: 1px solid #80808047;
                    height: auto;
                    // padding-top: 10px;
                    // background: rgb(48 51 59);
                    display: block;
                    padding: 0.625em 0.625em 0.9375em;
                    text-align: center;
                    position: relative;
                    .short {
                        display: none;
                        width: 100%;
                    }
                    .long {
                        display: inline-block;
                        width: 11.25em;
                    }
                    .spinning {
                      display: block;
                      position: absolute;
                      width: 1.25em;
                      right: calc(50% + -2.875em);
                      transform: rotate(0deg);
                      transform-origin: 50% 53%;
                      top: 1.3125em;
                      transition: all 0.75s ease;
                      @media screen and (max-width: $breakpoint-mobile-medium-icons) {
                          right: 16.75%;
                      }
                      @media screen and (max-width: $breakpoint-mobile-min) {
                          // display: none;
                      }
                    }
                    @media screen and (max-width: 6px) {
                        background: conic-gradient(from 0deg at 100% 100%, #30333b 270deg, #151616 360deg);
                        width: 3.75em;
                        min-width: 0;
                        padding: 0.1875em 0.625em;

                        .short {
                            display: inline-block;
                            margin: 0;
                            display: none;
                        }
                        .long {
                            display: none;
                        }
                        .spinning {
                          top: 0;
                          right: 0;
                          width: 2em;
                        }
                    }
                    &:hover {
                      .spinning {
                          transform: rotate(360deg);
                      }
                  }
                }
                .left-sidebar-content {
                    height: 100%;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    padding-top: 0.0625em;
                    margin-top: -0.5em;
                    transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
                    &::-webkit-scrollbar {
                        width: 0.75em !important;
                        height: 0.75em;
                    }
                }
                ul {
                    margin: 0;
                    padding: 0;
                    li {
                        list-style: none;
                        padding: 0;
                        &.accordion-nav {
                            & > .text {
                                font-family: "NunitoSans-Light";
                                font-size: 1.25em;
                                padding-left: 1em;
                            }
                            ul li a {
                                padding-left: 2em;
                            }
                      &.accordion-nav {
                        margin: .25em 0;
                        padding: .25em 0;
                        border: 1px solid;
                        border-image: linear-gradient(to right, transparent, rgb(88, 88, 88), transparent) 100% 1;
                        border-top: none;
                      }
                    }
                  }
                }



                .sidebar-section {
                    margin-bottom: 0.25em;
                    padding: .5em 1em;
                    border-bottom: 1px solid grey;
                    &.applied-filters-section {
                        margin-bottom: 0;
                        border-bottom: none;
                        padding: 0;
                    }
                    &.sidebar-nav-section, &.links {
                        padding: 1em 0 1em;
                    }

                    a {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        font-family: "NunitoSans-ExtraLight";
                        font-size: 1em;
                        line-height: 1.25;
                        height: 2.8125em;
                        color: white;
                        padding: 0.5em 1em;
                        transition: all .5s ease;
                        transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
                        cursor: pointer;

                        &:hover {
                            text-decoration: none;
                            background-color: #025aff;
                            background-color:#3f82ff;
                            background-color: #77a7ff;
                        }
                        &.active {
                            background-color: #3d73d7;
                        }
                        & > fa-icon, & > .icon {
                            margin-right: 0.75em;
                            position: relative;
                            width: 1.5625em;
                            img {
                                width: 1.5em;
                            }
                            .icon-1 {
                                margin-left: -0.125em;
                                margin-top: -0.125em;
                            }
                            .icon-2 {
                                position: absolute;
                                top: calc(50% - 0.4em);
                                left: calc(50% - 0.20em);
                                text-shadow: 3px 0px 5px black;
                                z-index: 1000000;
                                color: white;
                                filter: drop-shadow(-2px -2px 1px #30333b);
                            }
                        }
                        span.text {
                            line-height: 1;
                        }
                        & > fa-icon {
                            font-size: 1.25em;
                        }
                        &:hover {
                            text-decoration: none;
                            background-color: #025aff;
                            background-color:#3f82ff;
                            background-color: #77a7ff;
                            .icon-2 {
                                filter: drop-shadow(-2px -2px 1px #77a7ff);
                            }
                        }
                        &.active {
                            background-color: #3d73d7;
                            .icon-2 {
                                filter: drop-shadow(-2px -2px 1px #3d73d7);
                            }
                        }
                        &.sidebar-icon-button {
                            display: block;
                            margin: 0.5em auto;
                            font-size: 2em;
                            color: #78a8ff;
                            transition: all .35s ease-in-out;
                            transform: rotate(0deg);
                            padding: 0;
                            text-align: center;
                            line-height: 1;
                            height: 1em;

                            &:hover {
                                background: none;
                                color: #3d73d7;
                            }

                            & > fa-icon {
                                margin: 0;
                            }
                            &.options-button {
                                transform-origin: 40% 50%;
                                & > fa-icon {
                                    font-size: 1.125em;
                                }
                            }
                            &.open {
                                transform: rotate(-90deg);
                            }
                        }
                        &.uses-icon {
                            fa-icon {
                                position: relative;
                                transform: rotate(90deg);
                                &:after {
                                    position: absolute;
                                    content: "";
                                    width: 1em;
                                    height: 0.1875em;
                                    background: white;
                                    border: 0.0625em solid black;
                                    top: 0.625em;
                                    left: -0.0625em;
                                    transform: rotate(66deg);
                                    display: block;
                                    border-radius: 0.25em;
                                }
                            }
                        }
                    }

                    p {
                        padding: 0;
                        margin: 0;
                    }

                    label {
                        font-family: 'NunitoSans-ExtraLight';
                        margin-bottom: 0;
                        padding: 0.75em 0 0.25em;
                        letter-spacing: 0.5px;
                        color: #e9ecef;
                        font-weight: normal;
                        &.required {
                            padding: 0 0 .25em .85em;
                        }
                    }

                    &.user-info {
                        // border-bottom: none;
                        padding: .5em 1em 1em;
                        // overflow: hidden;
                        text-overflow: ellipsis;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        flex-direction: row-reverse;
                        p.long {
                            display: inline;
                        }
                        p.short {
                            display: none;
                        }
                        user-role-icon {
                            display: inline-block;
                            font-size: 1.25em;
                            text-align: center;
                            margin-right: 0.75em;
                        }
                    }

                    &.sidebar-license-info {
                      p {
                        display: flex;
                        align-items: baseline;
                        margin-bottom: 0.5em;
                        &:last-child {
                          margin-bottom: 0;
                        }
                        span.label {
                          display: inline-block;
                          font-family: "calibri";
                          transition: all 0.25s ease;
                          color: white;
                          line-height: 1;
                          margin-right: 1em;
                          &:after {
                            content: ":";
                          }
                        }
                        span.value {
                          display: inline-block;
                          transition: max-height 0.35s ease, opacity 0.25s ease 0.3s;
                          font-family: "NunitoSans-Light";
                          color: #9eb8e7;
                          font-size: 1.125em;
                          max-height: 0;
                          opacity: 0;
                          max-width: 100%;
                          max-height: 2.25em;
                          opacity: 1;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          line-height: 1;
                          .prep {
                            color: white;
                            margin: 0 0.25em;
                            font-size: 0.75em;
                          }

                        }
                      }
                    }

                    .form-control {
                        background-color: transparent;
                        color: white;
                        border: none;
                    }

                    // .user-role {
                    //     display: none;
                    // }
                    user-role-icon {
                        display: inline-block;
                        font-size: 1.125em;
                        text-align: center;
                    }

                    .user-roles {
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        padding: 0;
                        & > li {
                            margin-right: .75em;
                            font-size: 1.125em;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: .125em 0;
                            line-height: 1;
                            color: white;
                            width: auto;
                            height:1.875em;
                            &:last-of-type {
                                margin-right: 0;
                            }
                            &.bigger-icon {
                                font-size: 1.25em;
                            }
                        }
                    }


                    &.bottom-icons {
                        position: absolute;
                        width: 100%;
                        bottom: 1em;
                        padding: 0;
                        margin-bottom: 0;
                        border-bottom: none;
                        position: relative;
                        bottom: 0;
                        padding: 1em 0;
                        & > ul {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            & > li {
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 1.25em;
                                padding: 0;
                                width: 30%;
                                text-align: center;
                                color: rgba(255, 255, 255, 0.5);
                                & > div {
                                    position: relative;
                                    display: block;
                                    max-width: 2em;
                                }
                                & > a, & > div > a {
                                    display: block;
                                    height: auto;
                                    max-width: 2em;
                                    padding: 0;
                                    cursor: pointer;
                                    @media #{$mq-retina} {
                                        max-width: 1.75em;
                                      }
                                    img {
                                        display: block;
                                        width: 100%;
                                    }
                                    &:hover {
                                        background: none;
                                    }
                                    &.info-links-button {
                                        position: relative;
                                        &:after {
                                            content: "";
                                            background: url('/assets/images/infoIconHover.png');
                                            background-size: 100%;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            z-index: 1;
                                            opacity: 0;
                                            transition: all .35s ease;
                                        }
                                        &:hover:after {
                                            opacity: 1;
                                        }
                                    }
                                    &.apps-icon {
                                        position: relative;
                                        &:after {
                                            content: "";
                                            background: url('/assets/images/bentoIconHover.png');
                                            background-size: 100%;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            z-index: 1;
                                            opacity: 0;
                                            transition: all .35s ease;
                                        }
                                        &:hover:after {
                                            opacity: 1;
                                        }
                                    }
                                    &.logout-button {
                                        position: relative;
                                        &:after {
                                            content: "";
                                            background: url('/assets/images/powerOff2a.png');
                                            background-size: 100%;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            z-index: 1;
                                            opacity: 0;
                                            transition: all .35s ease;
                                        }
                                        &:hover:after {
                                            opacity: 1;
                                        }
                                    }

                                }
                            }
                        }
                        #dropdown-animated {
                            background-color: black;
                            top: -4px !important;
                            animation: fadeOutToNone 5s ease;
                            &.show {
                                animation: fadeInFromNone 0.5s ease-out;
                            }
                            li {
                                width: 100%;
                                margin-top: 0;
                                a {
                                    color: white;
                                    @media #{$mq-retina} {
                                        font-size: 0.75em;
                                      }
                                }
                            }
                        }
                    }

                    .ng-select .ng-select-container {
                        color: #f8f9fa;
                        background-color: #262c36;
                        border: 1px solid #000;
                        font-family: 'NunitoSans-Light';
                        font-size: 1.125em;
                        letter-spacing: 1px;
                        @media screen and (max-width: 1200px) {
                            font-size: .875em;
                            letter-spacing: 0.5px;
                        }
                    }
                    .ng-select.ng-select-opened>.ng-select-container {
                        background: #1a1b1c;
                        border-color: #2b3952;
                        color: white;
                    }
                    .ng-dropdown-panel.ng-select-bottom {
                        border-radius: 0;
                        transition: all 0.5s ease;
                        box-shadow: 2px 2px 10px -5px rgb(0 0 0 / 43%);
                        border: none;
                    }
                    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
                        font-family: 'NunitoSans-ExtraLight';
                        font-weight: 100;
                        transition: all .35s ease;
                        background-color: #212529;
                        color: rgb(240 242 247);
                    }
                    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
                        background-color: #2b3952;
                        color: #fff;
                    }
                    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
                    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
                        color: #f0f2f7;
                        background-color: #000000;
                    }


                }
                .space-filler {
                    flex-grow: 1;
                    height: auto;
                }
                .sidebar-form-options {
                    display: flex;
                    flex-direction: column;
                    .form-group {
                        margin-bottom: 1em;
                    }
                    button {
                        align-self: flex-end;
                        margin-top: 1em;
                    }
                }
            }
            .subpage {
                // padding:  1.25em;
                position: relative;
                z-index: 10;
                flex-grow: 1;
                background: linear-gradient(90deg, #78888e47, transparent 3%, transparent 96%, #78888e47) #3e5a7f24;
                transition: all 0.5s ease-in-out, width 0.05s ease;
                margin-left: calc(25vw -  3.125em);

                width: calc(75vw -  3.125em);
                transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);

                @media screen and (max-width: 1000px) {
                    margin-left:  12.5em;
                    width: calc(100% - 12.5em);
                }
                @media screen and (min-width: 1400px) {
                    margin-left:  18.75em;
                    width: calc(100% - 18.75em);
                }

                @media #{$mq-retina} {
                    margin-left: 18.75em;
                }

                .subpage-content {
                    position: relative;
                    padding:  1.25em;
                    @media screen and (max-width: 360px) {
                      padding: 0.25em;

                    }
                }

                .app-page-header {
                    background: white;
                    padding: 1em 1.5em;
                    margin: 1em auto;
                    border: 1px solid #a29f9f;
                    box-shadow: 3px 6px 10px -5px #00000073;

                    &.header-with-actions {
                        display: flex;
                        justify-content: space-between;
                        .page-actions {
                            button {
                                margin: -0.25em 0;
                            }
                        }
                    }

                    & > h2 {
                        margin: 0;
                        font-family: 'NunitoSans-SemiBold';

                        font-size: 1.5em;
                        padding: 0;
                        color: rgb(100,102,108);

                        & > fa-icon {
                            color: #3d73d7;
                            margin-right: 0.5em;
                        }
                        .downloads-product-name {
                            font-family: 'NunitoSans-SemiBold';
                            font-size: 0.875em;
                            color: #0d3463;
                            padding-left: 0.25em;
                        }
                        &.two-level-title {
                            .category {
                                margin-bottom: 0.25em;
                                display: block;
                                font-family: 'NunitoSans';
                                font-size: 0.75em;
                                font-weight: 100;
                                color: #3d73d7;
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                p {
                    padding:  1.25em;
                    &.input-instruction {
                        padding: .25em 0;
                        font-family: 'NunitoSans-LightItalic';
                        margin: 0;
                        padding: 0.25em 0;
                    }
                    &.field-display-value {
                        padding: 0;
                        .label {

                            &:after {
                                display: inline-block;
                                margin-right: 0.5em;
                                margin-left: 0.125em;
                                content: ":";
                            }
                        }
                        .value {
                            font-family: 'NunitoSans-SemiBold';
                            font-size: 1.125em;
                            .prep {
                              font-size: .875em;
                              font-family: NunitoSans-ExtraLightItalic;
                              margin: 0 .5em;
                            }
                        }
                    }
                }
                ul.input-instruction {
                  padding: .25em 0;
                  font-family: 'NunitoSans-LightItalic';
                }
            }

            @media screen and (max-width: 800px) {

                .left-sidebar {
                    z-index: 11;
                }

                .subpage {
                    padding: 15px;
                    .app-page-header {
                        padding: 0.75em 1em;
                        margin: 0.5em auto;
                        & > h2 {
                            font-size: 1.75em;
                        }
                    }
                }
            }

            @media screen and (max-width: 600px) {

                .left-sidebar1 {
                    min-width: 0;
                    width: 60px;
                    .sidebar-section {
                        padding: 0.5em 0.5em;
                        &.sidebar-nav-section, &.links {
                            padding: 0.5em 0;
                        }

                        a span {
                            display: none;
                        }
                        &.user-info {
                            padding: 0.5em 0.5em;
                            margin-top:  3.125em;
                            p {
                                text-align: center;
                                &.short {
                                    display: block;
                                }
                                &.long {
                                    display: none;
                                }
                            }
                        }
                        h5 {
                            display: none;
                        }
                        lib-form-field {
                            display: none;
                        }
                        button {
                            padding: 3px 10px;
                            font-size: 0;
                            width: 35px;
                            height: 35px;
                            background-image: url(/assets/images/circle_white.svg);
                            background-size: 60%;
                            background-repeat: no-repeat;
                            color: white;
                            background-position: center;
                        }
                        &.bottom-icons {
                            ul {
                                flex-direction: column;
                                li {
                                    width: 75%;
                                    margin-top: 0.5em;
                                    &:first-of-type {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .subpage {
                    margin-left:  3.125em;
                    padding: 10px;
                    .app-page-header {
                        margin: 0.25em auto;
                        padding: 0.5em 0.75em;
                        & > h2 {
                            font-size: 1.5em;
                        }
                    }
                }

            }
            @media screen and (max-width: 360px) {
              .subpage {
                padding: 5px;
              }
            }
            &.narrow-side-menu {
                .left-sidebar {
                    min-width: 0;
                    width: 60px;
                    z-index: 11;

                    ul li.accordion-nav {
                        & > .text {
                            display: none;
                        }
                        ul li a {
                            padding-left: 1em;
                        }
                    }
                    .closed-sidebar-item {
                        display: block;
                    }
                    .open-sidebar-item {
                        display: none;
                        &.floating {
                            display: block;
                            position: absolute;
                            background: #151616;
                            padding: 10px;
                            left: 60px;
                        }
                    }
                    // .user-roles {
                    //     display: none;
                    // }
                    // .user-role, user-role-icon {
                    //     display: block;
                    //     font-size: 1.125em;
                    //     text-align: center;
                    // }
                    .sidebar-logo .logo {
                        // background: conic-gradient(from 0deg at 100% 100%, #30333b 270deg, #151616 360deg);
                        background: conic-gradient(from 0deg at 100% 100%, #131517 270deg, #102140 360deg);
                        width:  3.75em;
                        min-width: 0;
                        padding: 0.1875em 0.625em;
                        padding: 0.375em 0.875em;

                        .short {
                            display: inline-block;
                            margin: 0;
                            display: none;
                        }
                        .long {
                            display: none;
                        }
                        .spinning {
                          top: 0;
                          right: 0;
                          width: 2em;
                          position: relative;
                        }
                    }
                    .left-sidebar-content {
                        padding-top: 2em;
                        margin-top: 1.5em;
                    }
                    .sidebar-section {
                        padding: 0.5em 0.5em 0.5em 0.375em;
                        &.sidebar-nav-section, &.links {
                            padding: 0.5em 0;
                        }
                        a {
                            justify-content: center;
                        }

                        a span.text {
                            display: none;
                        }
                        a > fa-icon {
                            margin-right: 0;
                            text-align: center;
                        }
                        &.user-info {
                            padding: 0.5em 0.5em;
                            margin-top: 0px;
                            overflow: visible;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-direction: column;
                            p {
                                text-align: center;
                                &.short {
                                    display: block;
                                    background: #3d73d7;
                                    font-family: "NunitoSans-Light";
                                    line-height: 2.75;
                                    padding: 0;
                                    font-size: 0.8125em;
                                    width: 2.615em;
                                    border-radius: 100%;
                                    height: 2.615em;
                                    box-shadow: 0px 2px 10px 2px rgb(0 0 0 / 75%);
                                }
                                &.long {
                                    display: none;
                                }
                            }
                            user-role-icon {
                                display: inline-block;
                                font-size: 1.125em;
                                text-align: center;
                                margin-right: 0;
                                margin-top: 0.5em;
                            }
                        }
                        &.sidebar-license-info {
                          p {
                            justify-content: center;
                            span.label {display: none;}
                          }
                        }
                        h5 {
                            // display: none;
                        }
                        lib-form-field {
                            // display: none;
                        }
                        button.submit-button {
                            padding: 3px 10px;
                            font-size: 0;
                            width: 35px;
                            height: 35px;
                            background-image: url(/assets/images/circle_white.svg);
                            background-size: 60%;
                            background-repeat: no-repeat;
                            color: white;
                            background-position: center;
                        }
                        &.bottom-icons {
                            ul {
                                flex-direction: column;
                                li {
                                    width: 75%;
                                    margin-top: 1em;
                                    &:first-of-type {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                        &.mobile-sidebar-hidden {
                            display: none;
                        }
                    }
                }

                .subpage {
                    margin-left:  3.125em;
                    @media #{$mq-retina} {
                        margin-left: 5em;
                    }
                }
            }
        }

    }
}

// .icon-set {
//     position: relative;
//     & > .small-icon {
//         position: absolute;
//         font-size: 0.625em;
//         bottom: -0.25em;
//         right: -0.25em;
//         z-index: 2;
//         color: #77a7ff;
//         text-shadow: 0px 0px 4px #181d21;
//         display: block;
//         filter: drop-shadow(-1px -1px 2px #181d21);
//     }
//     &.star > .small-icon {
//         color: gold;
//     }
//     &.shield > .small-icon {
//         right: 0em;
//         bottom: 0em;
//         font-size: 0.57em;
//     }
// }
.collapse-button {
    background: #3d73d7;
    color: white;
    font-size: 1em;
    z-index: 100000;
    line-height: 1;
    width:1.875em;
    height:1.875em;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateY(0deg);
    // transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition: left 0.5s cubic-bezier(0.22, 0.61, 0.36, 1), transform 0.35s ease-in-out 0.25s;
    border-radius: 50%;
    position: absolute;
    position: fixed;
    top: 3.5em;
    right: -0.9375em;
    padding: 0;
    cursor: pointer;
    background: radial-gradient(25% 25% at 33% 30%, #ffffff80, transparent), #3d73d7;

    left: calc(25vw - 3.125em);
    right: auto;
    margin-left: -0.9375em;
    @media screen and (max-width: 1000px) {
        left:  12.5em;
    }
    @media screen and (min-width: 1400px) {
        left:  18.75em;
    }
    @media #{$mq-retina} {
        left: 18.75em;
    }
    .narrow-side-menu & {
        left: 1.5625em;
        left: 1.75em;
        @media #{$mq-retina} {
            margin-left: 0;
        }
    }

    a {
        color: white;
        font-size: 1.75em;
        font-family: "fontawesome";
        line-height: 0.25;
        padding: 0;
        margin: 0;
        display: block;
        margin-top: -0.2em;
        margin-left: -0.0625em;
        animation: rotate-ball-back .5s ease-in-out 0.35s;
        animation-fill-mode: forwards;
    }


    // @media screen and (max-width: 600px) {
    //     right: 10px;
    //     a {
    //         animation: rotate-ball .5s ease-in-out 0.35s;
    //         animation-fill-mode: forwards;
    //     }
    // }
    // &.narrow-side-menu {
    //     left: 35px;
    //     transform: rotateY(180deg);
    // }

}


@keyframes rotate-ball {
    0% {
        transform: rotateY(0deg) translate3d(0px, 0px, 0px);
    }
    100% {
        transform: rotateY(180deg) translate3d(-2px, 0px, 0px);
    }
}
@keyframes rotate-ball-back {
    0% {
        transform: rotateY(180deg) translate3d(-2px, 0px, 0px);
    }
    100% {
        transform: rotateY(00deg) translate3d(0px, 0px, 0px);
    }
}

.narrow-side-menu .collapse-button {
    // right: 10px;
    a {
        animation: rotate-ball .5s ease-in-out 0.35s;
        animation-fill-mode: forwards;
    }
}

// inline editor list
ul.inline-edit-fields-list {
    margin: 0;
    padding: 0;
    li {
        list-style: none;
        // font-size: 1.125em;
        font-size: 1em;
        border-bottom: 1px solid #e6e6e678;
        padding: 0.75em 0.75em;
        transition: all .15s ease-in;
        display: flex;
        align-items: center;
        &.baseline {
          align-items: baseline;
        }
        @media screen and (max-width: 360px) {
          flex-direction: column;
          align-items: baseline;
          & > .value {
            overflow-wrap: break-word;
          }
          & > form {
            margin-top: 0.75em;
            width: 100%;
          }
        }
        label, span.label {
            font-family: "NunitoSans";
            margin-right: 2em;
            font-weight: normal;
            width: 25%;
            max-width: 150px;
            line-height: 1;
            font-family: 'calibri';
        }
        & > form {
          min-width: 200px;
        }
        span.value {
            font-family: "NunitoSans-SemiBold";
            font-size: 1.125em;
            font-size: 1em;
            line-height: 1;
            width: 100%;
            display: block;
            padding: 0.25em 1em .25em .5em;
            color: #646565;
            cursor: default;
            ul {
              padding-left: 0;
              li {
                padding: 0 0 1em;
                display: block;
              }
            }
        }
        &:last-of-type {
            border-bottom: none;
        }
        &:hover {
            background: rgb(163 182 217 / 7%);
        }
        lib-inline-edit {
            width: 100%;
            display: block;
            position: relative;
            align-self: flex-start;
            min-height: 2.125em;
        }
    }
}

.applied-filters-section {
    h4 {
        color: rgb(155 155 155);
        transition: all .5s ease;
        font-family: 'NunitoSans-Light';
        font-size: 1.125em;
        &.active {
            color: #f8f9fa;
        }
    }
}

.results-summary {
    font-family: "NunitoSans-Light";
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .text {
        width: 50%;
        text-align: right;
        padding-right: 0.25em;
        letter-spacing: 0.5px;
    }
    .value {
        font-family: 'NunitoSans';
        font-weight: normal;
        font-size: 1.25em;
        color: white;
        width: 50%;
        text-align: left;
        padding-left: 0.25em;
        letter-spacing: 1px;
    }
}

ul.applied-filters {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    li.applied-filter {
        position: relative;
        list-style: none;
        display: inline-block;
        font-weight: normal;
        border: 1px solid #9999994a;
        font-family: 'NunitoSans-Light';
        background: #262c36;
        color: #f8f9fa;
        font-size: 0.9125em;
        padding: .375em 2.5em .375em .5em !important;
        margin-bottom: 0.5em;
        .filter-name {
            &:after {
                content: ":";
                margin-right: 0.5em;
            }
        }
        .filter-value {
            font-family: 'NunitoSans-SemiBold';
        }
        .clear-button {
            width: 1em;
            height: 1em;
            text-indent: -100000em;
            position: absolute;
            color: #77a7ff;
            overflow: visible;
            background: none;
            border: none;
            right: 0.75em;
            top: calc(50% - .75em);
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                font-size: 1.5em;
                text-indent: 0;
                line-height: 1;
                font-family: FontAwesome;
                cursor: pointer;
                font-weight: 100;
            }
        }
    }
}

body.full-screen-code {
    overflow: hidden;
    .site-container .left-sidebar-container .subpage {
        z-index: 1000000000;
    }
}

.section-with-button {
    padding: 1em;
    border-bottom: 1px solid #e6e6e678;
    box-shadow: 2px 2px 10px -5px rgba(128,128,128,.5);
    margin-block: 1em;
    h3 {
        // text-decoration: underline;
        // text-underline-offset: 0.1875em;
        color: #3d73d7;
        font-size: 1.5em;
        font-family: NunitoSans-Light;
    }

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            padding: 0 !important;
            margin: 0 2em 0 0;
        }
        button {
            white-space: nowrap;
        }
    }
    &.danger {
        h3 {
            color: #d30707;
            font-family: NunitoSans-SemiBold;
        }
        & > div {
            border: 2px solid #d30707;
            padding: 0.5em 0.75em;
        }
    }
}

p.field-display-value {
    padding: 0;
    .label {

        &:after {
            display: inline-block;
            margin-right: 0.5em;
            margin-left: 0.125em;
            content: ":";
        }
    }
    .value {
        font-family: 'NunitoSans-SemiBold';
        font-size: 1.125em;
    }
}

.flex-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .flex-col {
    margin-right: 1em;
    h4 {margin-bottom: 0;}
    &:last-of-type {
      margin-right: 0;
    }
    &.col-no-shrink {
      flex-shrink: 0;
    }
    &.col-grow {
      flex-grow: 1;
      overflow: auto;
      & > * {
        width: 100%;
      }
    }

  }
}

body.status-bar .app-layout .site-container .left-sidebar-container .left-sidebar {
  top: 34px;
  height: calc(100vh - 34px);
}

.fields-group-border-with-title {
  background: transparent;
  border: 0.5px solid #d3d3d39e !important;
  padding: 0.5em 0.75em;
  margin-bottom: 2em;
  border-radius: 5px;
  margin-top: 1em;
  & > h5 {
    background: white;
    font-size: 1.125em;
    font-family: NunitoSans-Light;
    top: -1.25em;
    left: -0.375em;
    display: inline-block;
    padding: 0 0.25em;
    position: relative;
    color: #2d59ab !important;
  }
  & > div {
    margin-top: -1em;
  }
  .fields-group & {
    & > h5 {
      background: #f6f9ff;
    }
  }
  .fields-group.fields-group-disabled & {
    & > h5 {
      background: #f9f9f9;
    }
  }
}
.field-group .fields-group-border-with-title {
  & > h5 {
    background: #f6f9ff;
  }
}
.field-group.fields-group-disabled .fields-group-border-with-title {
  & > h5 {
    background: #f9f9f9;
  }
}
