.toggle-container {
    padding-left: 3em;
    &.right-sided {
      padding-left: 0;
      padding-right: 3em;
    }
    
    .toggle {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:before {
            left: -2.25em;
            width: 1.75em;
            pointer-events: all;
            border-radius: .5em;
        }
    }

    .toggle-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        font-size: 1em;
        cursor: pointer;
        padding: 0 !important;
        &:before {
            position: absolute;
            top: .25em;
            left: -1.5em;
            display: block;
            width: 1em;
            height: 1em;
            pointer-events: none;
            content: "";
            background-color: #fff;
            border: #adb5bd solid 1px;
            cursor: pointer;

            left: -2.25em;
            width: 1.75em;
            pointer-events: all;
            border-radius: .5em;
        }

        &:after {
            position: absolute;
            top: .25em;
            left: -1.5em;
            display: block;
            width: 1em;
            height: 1em;
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;


            top: calc(.25em + 2px);
            left: calc(-2.25em + 2px);
            width: calc(1em - 4px);
            height: calc(1em - 4px);
            background-color: #adb5bd;
            border-radius: .5rem;
            transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
            transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;        
        }
    }

    .toggle:checked~.toggle-label {
        &:before {
            background-color: #77a7ff;
            border-color: #3f82ff;
        }
        &:after {
            background-color: #fff;
            -webkit-transform: translateX(.75em);
            transform: translateX(.75em);
        }
    }

    fa-icon {
        display: none;
    }

    &.min, .narrow-side-menu & {
        & > fa-icon {
            display: block;
        }
        & > .text {
            display: none;
        }
    }

    .toggle:checked~.toggle-label:after {
        
    }
}
  
.narrow-side-menu .toggle-container {
    position: relative;
    padding-top: 2em;
    overflow: hidden;
    padding-left: 2.5em;
    text-align: center;
    // padding-left: 0;
    fa-icon {
        display: block;
        position: absolute;
        top: -1.5em;
        left: -1.75em;
        font-size: 1.125em;
    }
    .text {
        display: none;
    }
}


  
  .right-sided.toggle-container .toggle:before {
    left: auto;
    right: -2.25em;
  }
  
  
  
  .right-sided.toggle-container .toggle-label::after {
    left: auto;
    right: calc(-1.5em + 2px);
  }
  
  @media screen and (prefers-reduced-motion:reduce) {
    .toggle-container .toggle-label::after {
        transition: none;
    }
  }
  
  
  
  .toggle-container .toggle:disabled:checked~.toggle-label::before {
    background-color: rgba(0,123,255,.5);
    background-color: rgba(97, 64, 180, 0.5);
  }