

@font-face {
    font-family: fontawesome;
    // src: url(/assets/fonts/fontawesome-webfont.ttf);
    src: url(/assets/fonts/FontAwesome5/fa-solid-900.ttf);
    font-display: swap;
}



@font-face {
    font-family: 'calibri';
    src: url('/assets/fonts/calibri.ttf');
    font-display: swap;
}


@font-face {
    font-family: 'pirulen';
    src: url('/assets/fonts/pirulenrg.ttf');
    font-display: swap;
}




@font-face {
    font-family: 'NunitoSans';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'NunitoSans-SemiBold';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-SemiBold.ttf');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'NunitoSans-BoldItalic';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-BoldItalic.ttf');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'NunitoSans-Bold';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-Bold.ttf');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'NunitoSans-ExtraBold';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-ExtraBold.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NunitoSans-ExtraBoldItalic';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-ExtraBoldItalic.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NunitoSans-ExtraLight';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-ExtraLight.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NunitoSans-ExtraLightItalic';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-ExtraLightItalic.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NunitoSans-Italic';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-Italic.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NunitoSans-Light';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-Light.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NunitoSans-LightItalic';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-LightItalic.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NunitoSans-Black';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-Black.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NunitoSans-BlackItalic';
    src: url('/assets/fonts/Nunito_Sans/NunitoSans-BlackItalic.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


